/* eslint max-len: "off" */
import React from 'react';
import PropTypes from 'prop-types';
import * as Component from '../components';

function Terms({ language }) {
  return language === 'hr' ? (
    <>
      <Component.Scroll />
      <Component.Container grid>
        <Component.Grid>
          <h1 className="title" style={{ margin: '2rem 0' }}>
            Uvjeti korištenja
          </h1>
          <div className="card padded">
            <div className="content terms">
              {/* <h3>Cijena</h3> */}
              {/* <p>Cijena uključuje: kasko osiguranje, PDV.</p> */}
              {/* <h3>Period</h3> */}
              {/* <p>Najmanji period izdavanja je 1 dan.</p> */}
              {/* <h3>Odgovornost iznajmljivača</h3> */}
              {/* <p>Iznajmljivač se obvezuje osigurati
              vozilo najmoprimcu za određenu cijenu.</p> */}
              {/* <p> */}
              {/*  Najmoprimac postaje pravedni vlasnik automobila tijekom razdoblja najma. */}
              {/*  Iznajmljivač osigurava automobil. */}
              {/*  Iznajmljivač je dužan provjeriti stanje vozila */}
              {/*  i osigurati sigurno korištenje prije najma. */}
              {/*  Automobil koji osigurava iznajmljivač mora */}
              {/*  biti čist i napunjenog spremnika. */}
              {/* </p> */}
              {/* <h3>Ograničenja</h3> */}
              {/* <p> */}
              {/*  Najmoprimac mora imati najmanje tri godine vozačkog iskustva, */}
              {/*  mora koristiti automobil samo na području Hrvatske */}
              {/*  (osim ako iznajmljivač ne naznači drugačije). */}
              {/*  Kilometraža iznajmljenog automobila ograničava */}
              {/*  se na 4000 kilometara mjesečno. */}
              {/* </p> */}
              {/* <h3>Odgovornost najmoprimca</h3> */}
              {/* <p> */}
              {/*  Najmoprimac mora imati valjanu vozačku dozvolu */}
              {/*  koja im omogućuje vožnju unajmljenog automobila. */}
              {/*  Najmoprimac mora iznajmitelju dostaviti */}
              {/*  i putovnicu ili osobnu iskaznicu. */}
              {/*  Najmoprimac mora prije početka najma */}
              {/*  platiti cijelu cijenu najma. */}
              {/* </p> */}
              {/* <p> */}
              {/*  Najmoprimac mora ostaviti polog za automobil */}
              {/*  koji varira od 1000 HRK do 3000 HRK ovisno o klasi automobila. */}
              {/*  Može se upotrijebiti za pokrivanje oštećenja na automobilu, */}
              {/*  koja mogu nastati u razdoblju najma automobila. */}
              {/*  Prilikom vraćanja automobila najmoprimac mora platiti */}
              {/*  i potrošeno gorivo u spremniku, */}
              {/*  jer iznajmljivač automobila uvijek osigura */}
              {/*  puni spremnik goriva. */}
              {/* </p> */}
              {/* <h3>Odgovornost</h3> */}
              {/* <p> */}
              {/*  Najmoprimac preuzima potpunu odgovornost */}
              {/*  ako se tijekom razdoblja najma dogodi neosigurajući slučaj. */}
              {/*  Najmoprimac preuzima odgovornost za sve posljedice */}
              {/*  koje mogu nastati prilikom prijenosa automobila trećoj osobi. */}
              {/*  Ako najmoprimac prekrši pravila cestovnog prometa */}
              {/*  mora platiti sve povezane kazne. */}
              {/* </p> */}
              {/* <p> */}
              {/*  U slučaju krađe automobila najmoprimac mora nadoknaditi */}
              {/*  štetu ako ne vrati ključeve automobila i registracijske */}
              {/*  dokumente iznajmljivaču. */}
              {/* </p> */}
              <div className="col-24 col-md-16">
                <h2 id="op-i-uvjeti-najma">Opći uvjeti najma</h2>
                <p>Ovi Opći uvjeti najma, uključujući priloge, čine sastavni dio Ugovora o najmute se na istog primjenjuju u cijelosti, osim ako je posebnim odredbama samog Ugovora o najmu izrijekom predviđeno odstupanje od neke odredbe ovih Općih uvjeta, u kojem slučaju će prednost imati takve posebne odredbe iz Ugovora o najmu.</p>
                <h3 id="1-op-e-odredbe">1. Opće odredbe</h3>
                <p>
                  1.1.
                  {' '}
                  <strong>Najmodavac</strong>
                  {' '}
                  – trgovačko društvo LIVCORP doo, kao vlasnik brenda Livcar rent-a-car, sa sjedištem u Zagrebu (Republika Hrvatska), na adresi Ugljanska 6, (poslovnica u Rapskoj 12) Hrvatska, OIB: 94762205298
                </p>
                <p>
                  1.2.
                  {' '}
                  <strong>Najmoprimac</strong>
                  {' '}
                  – fizička ili pravna osoba koja ili u čije ime se unajmljuje vozilo, potpisuje Ugovor o najmu i Obrazac o stanju vozila, temeljem kojih preuzima vozilo u najam te je odgovorna za poštivanje svih odredbi ovih Općih uvjeta najma i Ugovora o najmu.
                </p>
                <p>
                  1.3.
                  {' '}
                  <strong>Vozač</strong>
                  {' '}
                  – fizička osoba navedena u Ugovoru o najmu kao “Vozač”, potpisuje Ugovor o najmu i Obrazac o stanju vozila temeljem kojih preuzima vozilo u najam te je odgovoran za poštivanje svih odredbi ovih Općih uvjeta najma i Ugovora o najmu. Vozač koji u ime Najmoprimca kao pravne osobe preuzima vozilo i potpisuje Ugovor o najmu, jamči da za to ima ovlaštenje i solidarno odgovara s tom pravnom osobom za poštivanje svih odredbi ovih Općih uvjeta najma i ispunjenje obveza iz Ugovora o najmu. Na Ugovoru o najmu može biti naveden jedan ili više vozača (Vozač 1, Vozač 2, Vozač 3, Vozač 4…).
                </p>
                <p>
                  1.4.
                  {' '}
                  <strong>Korisnik</strong>
                  {' '}
                  – Najmoprimac i Vozači iz točke 1.3. ovih Općih uvjeta se u daljnjem tekstu označavaju jednom riječju “Korisnik”. Svi Korisnici solidarno su odgovorni za ispunjenje i poštivanje svih odredbi Ugovora o najmu i Općih uvjeta najma.
                </p>
                <p>
                  1.5.
                  {' '}
                  <strong>Ugovor o najmu</strong>
                  {' '}
                  – ugovor koji se potpisuje prije preuzimanja vozila u najam, a kojim se definiraju marka, model i registarska oznaka vozila, datum preuzimanja i vraćanja vozila, cijena najma, način plaćanja najma, dodatne opreme i usluga, osiguranja uključena u cijenu, postupanje kod oštećenja i nepokretnosti vozila te druga prava i obveze koje ugovorne strane sklapanjem Ugovora o najmu u cijelosti prihvaćaju.
                </p>
                <p>
                  1.6.
                  {' '}
                  <strong>Obrazac o stanju vozila</strong>
                  {' '}
                  (“Check out/in report”) – interni akt Najmodavca koji sadrži sve bitne informacije o vozilu prije i nakon iznajmljivanja, posebice stanje kilometraže i opreme, količinu goriva, oštećenja te eventualne nedostatke na iznajmljenom vozilu. Obrazac o stanju vozila smatra se sastavnim dijelom svakog Ugovora o najmu i Vozač ga je dužan potpisati prije i nakon završetka Ugovora o najmu.
                </p>
                <p>
                  1.7.
                  {' '}
                  <strong>Cjenik za naplatu šteta</strong>
                  {' '}
                  – interni akt Najmodavca koji sadrži iznose šteta i naknada za sve marke i modele vozila, koje Najmodavac, sukladno Ugovoru o najmu, obračunava Korisniku u slučaju ogrebotina, udubljenja i dijelova vozila koje je potrebno popraviti/zamijeniti; u daljnjem tekstu “Cjenik”.
                </p>
                <p>
                  1.8.
                  {' '}
                  <strong>Cjenik najma, dodatnih osiguranja, naknada i opreme</strong>
                  {' '}
                  – interni akt Najmodavca kojim su definirane cijene najma svih modela vozila, dodatnih osiguranja koja Korisnik može prilikom sklapanja Ugovora dodatno ugovoriti, opreme koja se prilikom sklapanja Ugovora dodatno iznajmljuje te drugih naknada koje Najmodavac obračunava Korisniku temeljem ovih Općih uvjeta i Ugovora o najmu; u daljnjem tekstu “Cjenik”.
                </p>
                <p>
                  1.9.
                  {' '}
                  <strong>Izjava Najmoprimca o šteti na vozilu</strong>
                  {' '}
                  – interni obrazac Najmodavca koji je Vozač dužan ispuniti i potpisati ukoliko je za vrijeme trajanja Ugovora o najmu došlo do prometne nezgode ili nekog drugog događaja, koji je za posljedicu imao bilo kakvo vidljivo ili nevidljivo oštećenje na vozilu.
                </p>
                <p>
                  1.10.
                  {' '}
                  <strong>Izjava o privoli</strong>
                  {' '}
                  – izjava čijim potpisom Korisnik daje pristanak Najmodavcu da sukladno Općoj uredbi o zaštiti podataka prikuplja i obrađuje njegove osobne podatke u svrhu ispunjenja obveza iz Ugovora o najmu.
                </p>
                <p>
                  1.11.
                  {' '}
                  <strong>Neovlašteni korisnik</strong>
                  {' '}
                  – svaka osoba koja u Ugovoru o najmu nije navedena kao Najmoprimac/Vozač, kao i osoba koja ne ispunjava propisane uvjete za upravljanje iznajmljenom klasom i kategorijom vozila ili joj je ovlaštenje za upravljanje vozilom (vozačka dozvola) oduzeto.
                </p>
                <p>
                  1.12.
                  {' '}
                  <strong>Vozilo</strong>
                  {' '}
                  – vozilo precizno opisano u Ugovoru o najmu (marka, model, registarska oznaka).
                </p>
                <p>1.13. Vozač može biti:</p>
                <ul>
                  <li>osoba s navršenom 21 (dvadeset jednom) godinom starosti te važećom vozačkom dozvolom u trajanju od najmanje 2 (dvije) godine. Iznimno, za kategorije vozila viših klasa prema dogovoru sa najmodavcem minimalni uvjeti su: 25 (dvadeset pet) godina starosti i 5 (pet) godina vozačkog iskustva</li>
                  <li>
                    osobe koje posjeduju važeću kreditnu karticu koju Najmodavac prihvaća i kojom Korisnik garantira za obveze koje mogu nastati iz najma i u svezi s najmom vozila sukladno ovim Općim uvjetima i Ugovoru o najmu. Debitne kartice se prihvaćaju samo ukoliko ih odobri Najmodavac pri upitu.
                    Prilikom iznajmljivanja vozila Korisnik je dužan predočiti Najmodavcu izvornike važećih isprava (osobna iskaznica/putovnica i vozačka dozvola) kao preduvjet iznajmljivanja vozila.
                  </li>
                </ul>
                <h3 id="2-preuzimanje-i-povrat">2. Preuzimanje i povrat</h3>
                <p>2.1. Najmodavac se nakon sklapanja Ugovora o najmu obvezuje isporučiti vozilo Korisniku, koji udovoljava uvjetima za iznajmljivanje iz točke 1.13., u tehnički ispravnom stanju te sa svom potrebnom dokumentacijom, priborom i obveznom opremom.</p>
                <p>2.2. Korisnik potpisom Ugovora o najmu i Obrasca o stanju vozila potvrđuje da je preuzeo vozilo u ispravnom stanju, s pripadajućom opremom i potrebnom dokumentacijom te da prihvaća cijenu i sve ostale uvjete najma.</p>
                <p>2.3. Prilikom preuzimanja vozila, Korisnik je dužan na uobičajen način provjeriti stanje vozila i opreme te Najmodavcu prijaviti nedostatke, ukoliko ih ima, a Najmodavac ih je dužan evidentirati u Obrascu o stanju vozila.</p>
                <p>2.4. Prilikom povrata vozila iz najma Najmodavac je dužan pregledati vozilo i opremu s Korisnikom te evidentirati vidljive štete i nedostatke na vozilu, ukoliko ih ima.</p>
                <p>2.5. Korisnik je dužan vozilo vratiti u mjesto i u roku navedenom u Ugovoru o najmu, u stanju u kakvom ga je preuzeo, s pripadajućim dokumentima i svom opremom koju je zadužio na početku najma te količinom goriva koja mora odgovarati minimalno onoj u trenutku preuzimanja vozila.</p>
                <p>2.6. Gubitak dokumenata, ključeva, registarskih pločica ili opreme nije pokriveno niti jednim standardnim ni dodatnim osiguranjima te je Korisnik odgovoran za štetu koja iz toga proizađe, a koja će mu biti naplaćena sukladno Cjeniku Najmodavca.</p>
                <p>2.7. Preuzimanje i vraćanje vozila moguće je za vrijeme radnog vremena u poslovnicama Najmodavca, a preuzimanje i vraćanje izvan radnog vremena naplaćuje se po Cjeniku Najmodavca. Dostava i preuzimanje vozila izvan poslovnica Najmodavca moguće je uz prethodnu suglasnost Najmodavca i nadoplatu ovisno o udaljenosti od poslovnice.</p>
                <p>2.8. Ukoliko Korisnik vrati vozilo izvan radnog vremena poslovnice Najmodavca, odgovoran je za vozilo do trenutka kada ga pregledaju djelatnici Najmodavca. Korisnik je u tom slučaju odgovoran i za eventualna oštećenja na vozilu, parking/prometne prekršaje i slične kazne, sve do trenutka kada ga pregledaju djelatnici Najmodavca.</p>
                <h3 id="3-kori-tenje-vozila-i-obveze-korisnika">3. Korištenje vozila i obveze korisnika</h3>
                <p>3.1. Korisnik se obvezuje:</p>
                <ol>
                  <li>vozilo vratiti u mjesto i u roku utvrđenim Ugovorom o najmu, u stanju, s opremom i s količinom goriva u kojem ga je preuzeo,</li>
                  <li>produljenje ugovorenog roka trajanja najma osobno zatražiti od Najmodavca najmanje 24 (dvadeset četiri) sata prije isteka samog roka najma, u protivnom će se smatrati da je Korisnik protupravno prisvojio vozilo,</li>
                  <li>ne koristiti vozilo za obuku vozača, prijevoz ili vuču drugih vozila ili prikolica, plaćeni prijevoz putnika, utrke, ispitivanja izdržljivosti, ispitivanja brzine i u protuzakonite svrhe (npr. radi počinjenja kaznenih djela i drugih protupravnih radnji i prekršaja),</li>
                  <li>da će samo on koristiti vozilo, za vlastite potrebe i u skladu s namjenom vozila te da vozilo neće davati na korištenje neovlaštenim korisnicima i trećim osobama,</li>
                  <li>vozilo koristiti pravilno i primjenjivati svu razumnu pažnju tj. pažnju savjesnog gospodarstvenika prilikom korištenja, vožnje i parkiranja vozila,</li>
                  <li>ne pušiti u vozilu,</li>
                  <li>vozilo nakon napuštanja uvijek zaključati, zatvoriti prozore i uzeti ključeve i dokumentaciju vozila te ih uvijek imati pod osobnom kontrolom,</li>
                  <li>voziti samo po javnim cestama, informirati se o prometnim propisima u Hrvatskoj i drugim državama, poštujući sve prometne propise i regulaciju prometa,</li>
                  <li>brinuti o redovnoj tehničkoj ispravnosti vozila tj. redovito provjeravati i održavati na propisanoj razini sve tekućine u vozilu, osobito tekućinu za hlađenje, ulje, AdBlue aditiv, tlak u gumama i koristiti samo vrstu goriva specificiranu za iznajmljeno vozilo,</li>
                  <li>ne vršiti nikakve preinake na vozilu,</li>
                  <li>snositi sve troškove u svezi s pogonom vozila: gorivo, cestarine, mostarine, parkirne, prekršajne i druge slične naknade,</li>
                  <li>u vozilu ne prevoziti niti dopustiti prijevoz više putnika ili robe od maksimalno dopuštenog prema prometnoj dozvoli iznajmljenog vozila,</li>
                  <li>ne izlaziti vozilom izvan granica Republike Hrvatske, osim ako je to prethodno najavio Najmodavcu prilikom rezervacije ili preuzimanja vozila, za što će mu Najmodavac naplatiti naknadu sukladno Cjeniku,</li>
                  <li>ne preuzimati nikakve obveze u ime Najmodavca u svezi s vozilom i njegovom upotrebom,</li>
                  <li>ne davati unajmljeno vozilo u podnajam i ne posuđivati vozilo drugim osobama,</li>
                  <li>ne koristiti vozilo pod utjecajem alkohola, sredstava za smirenje, sredstava za spavanje, barbiturata, narkotika, halucinogenih i ostalih droga,</li>
                  <li>poštivati ograničenja brzine i drugih prometnih pravila određenih zakonom države u kojoj se vozilo vozi,</li>
                  <li>ne mijenjati podatke na mjeraču brzine ili mjeraču prijeđene kilometraže,</li>
                  <li>ako vozilo na instrument ploči ili na neki drugi način signalizira ili Korisnik smatra da vozilo zahtijeva mehanički pregled ili popravak, zaustaviti vožnju i o tome odmah obavijestiti Najmodavca,</li>
                  <li>osigurati da su svi vozači koji su ovlašteni koristiti vozilo tijekom trajanja najma u potpunosti upoznati sa svim odredbama ovih Općih uvjeta i Ugovora o najmu,</li>
                  <li>ako je vozilu nužna ili hitno potrebna neka servisna radnja, promjena pneumatika i sl., vozilo u tu svrhu staviti na raspolaganje Najmodavcu na području Republike Hrvatske,</li>
                  <li>u svrhu obavljanja tehničkog/preventivnog pregleda i produljenja registracije osigurati da se vozilo nalazi na području Republike Hrvatske i staviti ga na raspolaganje Najmodavcu.</li>
                </ol>
                <p>3.2. Ogriješi li se Korisnik o bilo koju odredbu iz točke 3.1. ovih Općih uvjeta, obvezuje se nadoknaditi Najmodavcu svaku štetu koja bi mu zbog toga nastala, a čiju će visinu utvrditi Najmodavac.</p>
                <p>3.3. Ugovorom o najmu Korisnik ne stječe pravo vlasništva nad vozilom, već isključivo pravo korištenja u skladu s odredbama Ugovora i ovih Općih uvjeta.</p>
                <p>3.4. Korisnik je odgovoran za unajmljeno vozilo tijekom cijelog vremena trajanja Ugovora o najmu, bez obzira na to je li vozilo u vožnji ili je parkirano.</p>
                <p>3.5. U slučaju kada Najmodavac nadoknadi bilo kakvu štetu nastalu u svezi s posjedovanjem, korištenjem ili stanjem vozila trećim osobama, odnosno kada zbog istog razloga plati bilo koji iznos, Korisnik priznaje Najmodavcu njegovo regresno pravo te se obvezuje nadoknaditi svaki tako isplaćeni iznos, zajedno s pripadajućim kamatama i troškovima.</p>
                <p>3.6. Ukoliko je Najmoprimac pravna osoba, isti može iznimno uz prethodnu pisanu suglasnost Najmodavca, unajmljeno vozilo dati na korištenje svojim zaposlenicima, koji ispunjavaju sve uvjete iz točke 1.13. ovih Općih uvjeta te ih je u tom slučaju dužan upoznati s ovim Općim uvjetima najma i Ugovorom o najmu. Korisnik potpisom Ugovora o najmu izrijekom potvrđuje da je u cijelosti odgovoran za postupanje tih osoba u svezi s vozilom te je dužan nadoknaditi Najmodavcu svu štetu i podmiriti sve troškove i druge naknade koje te osobe prouzroče u svezi s korištenjem vozila. Navedeno ni u kom slučaju ne oslobađa odgovornosti Korisnika za poštivanje svih odredbi ovih Općih uvjeta i Ugovora o najmu.</p>
                <h3 id="4-produljenje-najma">4. Produljenje najma</h3>
                <p>4.1. U slučaju da Korisnik želi produljiti ugovoreno vrijeme najma, o tome je dužan najmanje 24 (dvadeset četiri) sata prije isteka Ugovora o najmu obavijestiti Najmodavca i za to dobiti njegovu pisanu suglasnost. U tom slučaju, Korisnik mora doći u najbližu poslovnicu Najmodavca, potpisati i preuzeti Ugovor o najmu s novougovorenim datumom povrata vozila te ostaviti dodatnu garanciju ukoliko je potrebno.</p>
                <p>4.2. U slučaju ne postupanja po točki 4.1., smatrat će se da je Korisnik protupravno prisvojio vozilo te Najmodavac zadržava pravo da upotrijebi sve zakonske mjere kako bi Korisniku oduzeo predmetno vozilo.</p>
                <p>4.3. Ukoliko Korisnik vrati vozilo nakon datuma navedenog u Ugovoru o najmu, Najmodavac će izvršiti novi obračun cijene najma vozila sukladno Cjeniku.</p>
                <h3 id="5-pla-anje-najma">5. Plaćanje najma</h3>
                <p>5.1. Prilikom sklapanja Ugovora o najmu Korisnik mora kao garanciju plaćanja imati važeću i valjanu kreditnu karticu.</p>
                <p>5.2. Korisnik je suglasan s uzimanjem predautorizacije na kreditnoj kartici u iznosu koji ovisi o kategoriji iznajmljenog vozila. Oslobađanje predautoriziranog iznosa s kartice ovisi o banci izdavatelju kartice.</p>
                <p>5.3. Vlasnik kreditne kartice mora biti prisutan prilikom preuzimanja vozila u najam te njegovi podaci moraju biti navedeni u Ugovoru o najmu kao Najmoprimac ili Vozač. Nije moguće korištenje kreditne kartice kao garancije plaćanja bez prisutnosti vlasnika kreditne kartice.</p>
                <p>5.4. Korisnik je obvezan platiti osnovnu cijenu najma i sve dodatne usluge koje je koristio (npr. dječje sjedalo, GPS, naknadu za preuzimanje van radnog vremena i sl.), kao i sve dodatne naknade, usluge i troškove koji su navedeni u Ugovoru o najmu.</p>
                <p>5.5. Sredstvo plaćanja mogu biti: kreditna kartica, elektronska/debitna kartica, novčanice ili transakcijsko plaćanje (samo uz prethodnu suglasnost Najmodavca).</p>
                <p>5.6. Ukoliko Korisnik podmiruje svoje troškove za najam vozila kreditnom karticom, isti potpisom Ugovora o najmu daje ovlaštenje Najmodavcu da zaračuna troškove najma direktno vlasniku kreditne kartice i bez potpisanog slip obrasca.</p>
                <p>5.7. Potpisom Ugovora o najmu Korisnik daje ovlaštenje Najmodavcu da nositelju kreditne kartice naplati troškove štete na vozilu do visine franšize ili punog iznosa štete ako se nije pridržavao ovih Općih uvjeta najma, sukladno ugovorenom paketu osiguranja.</p>
                <p>5.8. Korisnik pristaje da na teret njegove kreditne kartice ili nekim drugim načinom plaćanja Najmodavac naplati sve troškove popravka, kvarova ili gubitka koji su otkriveni nakon što je vozilo vraćeno, a Korisnik nije prilikom povrata vozila o tome izvijestio Najmodavca.</p>
                <p>5.9. Ukoliko Korisnik podmiruje svoje obveze najma vozila na temelju ispostavljene ponude Najmodavca, isti je dužan podmiriti naznačeni iznos u roku i pod uvjetima naznačenim na ponudi.</p>
                <p>5.10. Korisnik se obvezuje podmiriti račun najkasnije u trenutku vraćanja vozila prema detaljima navedenim u Ugovoru o najmu. Iznos se plaća sukladno Cjeniku koji je na snazi u vrijeme potpisivanja Ugovora. U slučaju kašnjenja s plaćanjem, naplaćuje se zakonska zatezna kamata i troškovi opomena. U slučaju da se vozilo vrati na lokaciju različitu od one gdje je vozilo preuzeto, konačni obračun cijene najma radi poslovnica Najmodavca gdje je vozilo vraćeno.</p>
                <p>5.11. Korisnik potpisom Ugovora o najmu prihvaća da su za plaćanje svih stavki Ugovora o najmu odgovorni:</p>
                <ul>
                  <li>Najmoprimac</li>
                  <li>Vozači: za slučaj da Najmoprimac odbija u cijelosti ili dijelom platiti obvezu po Ugovoru o najmu vozila</li>
                </ul>
                <p>5.12. Obračun svih stavki Ugovora o najmu vozila će se vršiti u eurima. U slučaju preračunavanja valuta, koristit će se srednji tečaj Hrvatske narodne banke, ukoliko nije dogovoreno drugačije. Od 01.01.2023 koristi se određeni tečaj za preračunavanje eura u kune.</p>
                <h3 id="6-posebne-obveze-korisnika-u-slu-aju-o-te-enja-kvara-prometne-nesre-e-kra-e-gubitka-dokumentacije">6. Posebne obveze korisnika u slučaju oštećenja, kvara, prometne nesreće, krađe, gubitka dokumentacije</h3>
                <p>6.1. Korisnik se obvezuje da će u slučaju nezgode štititi interese Najmodavca i njegovog osiguravajućeg društva tako što će:</p>
                <ol>
                  <li>zabilježiti osobne podatke sudionika nesreće (ime, prezime, OIB, adresu, broj osobne iskaznice, broj vozačke dozvole, vlasnika vozila, korisnika vozila, osiguravajuće društvo vozila, broj police AO, kontakt telefon i dr.),</li>
                  <li>osigurati ili ukloniti vozilo prije nego li ga napusti,</li>
                  <li>o šteti (ako je ona i neznatna) obavijestiti najbližu policijsku postaju i Najmodavca te pribaviti policijski zapisnik,</li>
                  <li>u slučaju oštećenja ili ako u nezgodi ima povrijeđenih osoba kao i u svim slučajevima očigledne krivnje drugih osoba odmah pozvati i sačekati dolazak policijskih službenika da izvrše službeni očevid i o tome obvezno izvijestiti Najmodavca,</li>
                  <li>pri povratu vozila priložiti sva policijska izvješća o nezgodi ili šteti, kao i rezultate alkotesta,</li>
                  <li>pri povratu vozila ispuniti Izjavu najmoprimca o šteti na vozilu i priložiti kopije vozačke dozvole (s obje strane),</li>
                  <li>g) u slučaju sudara, udara i sl. te posljedične nepokretnosti vozila, obvezno pozvati policiju kako bi sačinila zapisnik i izvršila alkotestiranje zbog potencijalno velikih (skrivenih) oštećenja na vozilu.</li>
                </ol>
                <p>6.2. Propusti li Korisnik u slučaju nezgode postupiti po odredbama iz točke 6.1. odgovara za sve posljedice i štete koje bi Najmodavcu iz tog propusta nastale te će biti terećen za puni iznos štete.</p>
                <p>6.3. Ako je vozilo koje je sudjelovalo u prometnoj nesreći, oštećeno, razbijeno ili zahtijeva popravak ili spašavanje te ako nije više tehnički ispravno, bez obzira na uzrok, Korisnik mora o tome odmah obavijestiti Najmodavca i popuniti Izjavu najmoprimca o šteti na vozilu te osigurati vozilo od propadanja i nastupa još veće štete.</p>
                <p>6.4. Korisnik ne smije organizirati ili poduzimati bilo kakve popravke bez pisanog odobrenja Najmodavca, osim u mjeri u kojoj je to potrebno kako bi se spriječila daljnja šteta na vozilu ili ostaloj imovini.</p>
                <p>6.5. U slučaju prometne nesreće ili krađe/nestanka vozila, Korisnik je obvezan odmah pozvati policiju i zatražiti zapisnik o događaju te o tome bez odgode obavijestiti Najmodavca.</p>
                <p>6.6. Trošak prijave i ponovne izrade izgubljene popratne dokumentacije za vozilo Korisniku se naplaćuje sukladno Cjeniku Najmodavca.</p>
                <h3 id="7-odr-avanje-vozila">7. Održavanje vozila</h3>
                <p>7.1. Korisnik se obvezuje održavati vozilo u ispravnom stanju, redovito provjeravati motor, razinu tekućina u vozilu, posebice ulja, rashladne tekućine, AdBlue aditiva te pritisak u gumama. Propusti li Korisnik navedeno učiniti, odgovoran je za svu štetu koja Najmodavcu zbog toga nastane.</p>
                <p>7.2. U slučaju da se na iznajmljenom vozilu za vrijeme najma pojavi potreba obavljanja redovnog servisa (prema stanju kilometraže ili vozilo to na neki drugi način signalizira), Korisnik o tome mora obavijestiti Najmodavca i staviti mu vozilo na raspolaganje na području Republike Hrvatske kako bi bilo moguće obaviti redovni servis. Najmodavac Korisniku u tom slučaju stavlja na raspolaganje zamjensko vozilo, ukoliko je tako dogovoreno.</p>
                <p>7.3. Korisnik potpisom Ugovora o najmu potvrđuje da je vozilo preuzeo u tehnički ispravnom stanju i sa svom potrebnom opremom te je dužan u najkraćem roku Najmodavcu prijaviti bilo kakvu okolnost koja utječe/mijenja taj status, kao npr. istrošeni pneumatici, oštećen dio karoserije (npr. retrovizor), istrošene žarulje, odnosno sve drugo što bitno utječe na sigurnost vožnje. Propusti li Korisnik to učiniti, odgovoran je za svu štetu koja Najmodavcu zbog toga nastupi.</p>
                <p>7.4. Korisnik je dužan Najmodavcu odmah javiti bilo kakva signalna upozorenja na vozilu, kao npr. upozorenje za servis, visoku temperaturu u motoru, zvuk struganja pločica, tragove kapanja ulja ispod vozila i sl. Propusti li Korisnik to učiniti, odgovoran je za svu štetu koja Najmodavcu zbog toga nastane.</p>
                <p>7.5. Najmodavac će nadoknaditi Korisniku neophodne troškove za ulje, mazivo, redovito servisiranje i sitne popravke, koji su nastali tijekom najma, ukoliko su troškovi nastali uz prethodnu obavijest i pisano odobrenje Najmodavca, osim troškova pranja iznajmljenog vozila, a sve to temeljem predočenog računa po obavljenom plaćanju. Da bi ga Najmodavac priznao, račun mora glasiti na Najmodavca (s potpunim i točnim podacima). Ako se utvrdi da je Korisnik neosnovano ili nepotrebno zamijenio neki sklop, dio ili uređaj na vozilu, Najmodavac neće Korisniku isplatiti vrijednost tog dijela, sklopa ili uređaja. Za nadoknadu navedenih troškova Korisnik mora dobiti suglasnost ovlaštene osobe Najmodavca, u protivnom nadoknada neće biti priznata.</p>
                <h3 id="8-osiguranja-pokri-e-tete">8. Osiguranja/pokriće/štete</h3>
                <p>8.1. Sva vozila osigurana su protiv odgovornosti za štetu prouzročenu trećoj osobi.</p>
                <p>
                  8.2.
                  {' '}
                  <strong>Odgovornost u šteti / Učešće u šteti (franšiza)</strong>
                </p>
                <p>Odgovornost Korisnika (osim odgovornosti za nestanak dodatne opreme i dijelova vozila) može se ograničiti do iznosa učešća u šteti (franšizi), plaćanjem unaprijed definirane naknade prema Cjeniku Najmodavca, što mora biti navedeno na Ugovoru o najmu, ali samo pod uvjetom da Korisnik štetu nije uzrokovao kršenjem odredbi ovih Općih uvjeta, Ugovora o najmu i zakonskih propisa, odnosno namjerno ili nepravilnom uporabom vozila.</p>
                <p>8.3. Prilikom povrata vozila, djelatnik Najmodavca će pregledati vozilo, utvrditi eventualna oštećenja, usporediti ih s Obrascem o stanju vozila prilikom preuzimanja te Korisniku naplatiti iznos štete u skladu s važećim Cjenikom Najmodavca.</p>
                <p>8.4. Ako vozilo ima takva oštećenja da nije moguće odmah procijeniti visinu nastale štete, zatražit će se službena procjena iznosa popravka vozila te će ona biti osnova za postupanje prema Korisniku.</p>
                <p>
                  8.5.
                  {' '}
                  <strong>CDW (Collision Damage Waiver)</strong>
                  {' '}
                  – naknada za rizik s učešćem u šteti
                </p>
                <p>Plaćanjem ove naknade odgovornost Korisnika za štetu na vozilu ograničena je do iznosa franšize/učešća u šteti ukoliko se pridržava svih odredbi ovih Općih uvjeta najma.</p>
                <p>Ukoliko Korisnik ima CDW pokriće, odgovara za štetu najviše do iznosa franšize, sukladno Cjeniku Najmodavca za ugovorenu klasu/grupu vozila. CDW-om nisu pokriveni rizici: uništenje/oštećenje automobilskih guma, naplatka ili poklopca naplatka, uništenje/oštećenje donjeg postroja vozila, unutrašnjosti vozila, svih stakala vozila i sva oštećenja za koja Korisnik Najmodavcu nije predočio policijski zapisnik.</p>
                <p>
                  8.6.
                  {' '}
                  <strong>TP (Theft Protection)</strong>
                  {' '}
                  – naknada za rizik krađe vozila s učešćem u šteti
                </p>
                <p>Plaćanjem ove naknade Korisnikova odgovornost za štetu zbog krađe vozila ograničena je do iznosa franšize/učešća u šteti sukladno Cjeniku Najmodavca, ukoliko se pridržava svih odredbi ovih Općih uvjeta najma.</p>
                <p>
                  8.7.
                  {' '}
                  <strong>CDR (Collision Damage Reduction)</strong>
                  {' '}
                  – učešće u šteti može se djelomično otkupiti plaćanjem dodatka za djelomični otkup franšize prema važećem Cjeniku, u kojem slučaju Korisnik plaća smanjeni iznos franšize sukladno Cjeniku, ukoliko se pridržava svih odredbi ovih Općih uvjeta najma.
                </p>
                <p>Plaćanjem CDR pokrića nisu pokriveni rizici: uništenje/oštećenje guma, naplatka ili poklopca naplatka, uništenje/oštećenje donjeg postroja vozila, unutrašnjosti vozila (osim ako je unutrašnjost oštećena u nesreći), svih stakala vozila te sva oštećenja bez priloženog policijskog zapisnika. CDR pokriće ne isključuje obvezu kontaktiranja policije i Najmodavca kod oštećenja vozila te pribavljanje policijskog zapisnika.</p>
                <p>
                  8.8.
                  {' '}
                  <strong>SCDW (Super Collision Damage Waiver)</strong>
                  {' '}
                  – učešće u šteti (franšiza) iz točaka broj 8.5. i 8.7. može se otkupiti plaćanjem dodatka za otkup franšize prema važećem Cjeniku Najmodavca (SCDW), u kojem slučaju Korisnik ne plaća iznos franšize ukoliko se pridržava svih odredbi ovih Općih uvjeta najma.
                </p>
                <p>Plaćanjem SCDW pokrića nisu pokriveni rizici: uništenje/oštećenje guma, naplatka ili poklopca naplatka, uništenje/oštećenje donjeg postroja vozila, unutrašnjosti vozila (osim ako je unutrašnjost oštećena u nesreći), svih stakala vozila te sva oštećenja bez policijskog zapisnika. SCDW pokriće ne isključuje obvezu kontaktiranja policije i Najmodavca kod oštećenja vozila te pribavljanje policijskog zapisnika.</p>
                <p>
                  8.9.
                  {' '}
                  <strong>WUG (Wheels, Undercarriage &amp; Glass Insurance)</strong>
                  {' '}
                  – plaćanjem ove naknade ugovara se pokriće za uništenje/oštećenje guma, naplatka ili poklopca naplatka, uništenje/oštećenje donjeg postroja vozila i stakala vozila.
                </p>
                <p>WUG pokriće ne isključuje obvezu kontaktiranja policije i Najmodavca kod oštećenja vozila te pribavljanje policijskog zapisnika.</p>
                <p>
                  8.10.
                  {' '}
                  <strong>PAI (Personal Accident Insurance)</strong>
                  {' '}
                  - plaćanjem dnevnog dodatka za osiguranje putnika, vozač i putnici u iznajmljenom vozilu osigurani su od nesretnog slučaja za slučaj smrti, invaliditeta i troškove liječenja ozljeda po uvjetima i do iznosa koji je propisalo osiguravajuće društvo s kojim Najmodavac ima sklopljenu policu osiguranja od nezgode.
                </p>
                <p>8.10.1. Sekcija I</p>
                <p>Osiguranje vozača i ostalih suputnika od posljedica prometne nezgode za vrijeme upravljanja i vožnje motornim vozilima u vlasništvu ili najmu ugovaratelja osiguranja (t.d. LIVCORP d.o.o)</p>
                <ul>
                  <li>
                    <p>u slučaju smrti uslijed nezgode vozača i putnika od posljedica nesretnog slučaja: 50.000,00 kn</p>
                  </li>
                  <li>
                    <p>u slučaju trajne invalidnosti: do 100.000,00 kn</p>
                  </li>
                </ul>
                <p>8.10.2 Sekcija II</p>
                <ul>
                  <li>osiguranje vozača i suputnika u slučaju prometne nezgode izazvane krivnjom vozača pri uporabi cestovnog vozila: prošireno AO Plus pokriće sa svotom osiguranja do 50.000,00 kn po osobi i osiguranom slučaju.</li>
                </ul>
                <p>8.11. Da bi ostvario pravo na ograničenje odgovornosti ili otkup učešća u šteti, Korisnik je dužan dokazati da je vozilo pravilno koristio, da se pridržavao ovih Općih uvjeta, Ugovora o najmu i propisa, kao i odgovornost treće strane za nastanak štete vjerodostojnom dokumentacijom (policijski zapisnik, Europsko izvješće i dr.) i to najkasnije prilikom povrata vozila/završetka najma, odnosno prilikom obračuna cijene, naknada i šteta od Najmodavca. U protivnom neće moći koristiti ograničenje odgovornosti ili otkup učešća u šteti.</p>
                <p>8.12. Gubitak prava na ograničenje odgovornosti i gubitak prava iz osiguranja</p>
                <p>Sve štete koje su na vozilu prouzročene namjerno i grubom nepažnjom Korisnika, nisu uključene ni u jednu vrstu osiguranja/pokrića te će kao takve biti naplaćene Korisniku u punom iznosu.</p>
                <p>8.13. Plaćanjem/uključenjem CDW, TP, CDR, WUG, SCDW, BASIC PROTECT, SMART PROTECT ili PREMIUM PROTECT paketa osiguranja ne umanjuje se odgovornost Korisnika ukoliko je:</p>
                <ol>
                  <li>upravljao vozilom pod utjecajem alkohola, droga ili narkotika,</li>
                  <li>upravljao vozilom kada je isto nesigurno ili nepodobno za vožnju, a takvo stanje je nastalo tijekom trajanja najma, što je uzrokovalo ili doprinijelo oštećenju vozila te je Korisnik bio svjestan ili morao biti svjestan nesigurnosti ili nepodobnosti vozila,</li>
                  <li>nastao mehanički kvar, oštećenje motora ili pogonskog mehanizma vozila i/ili električni ili elektronički kvar kao rezultat nepravilne uporabe vozila. Ovo izuzeće također se odnosi na oštećenja motora ili prijenosnog sustava izravno nastalog zbog bilo kojeg mehaničkog kvara ili loma,</li>
                  <li>došlo do oštećenja na vozilu zbog nedostatka motornog ulja, ulijevanja pogrešnog ulja ili goriva, nedostatka ulja za mjenjač ili diferencijal, sredstava za hlađenje, AdBlue aditiva, kao i oštećenja kvačila ili mjenjača,</li>
                  <li>vozilo korišteno za utrke, obuku vozača, ispitivanja izdržljivosti, brzine i pouzdanosti, rally utrkama ili natjecanjima, ili za testiranje i u pripremi za bilo što od navedenog,</li>
                  <li>propustio, nakon što je napustio vozilo, zaključati ga sa zatvorenim prozorima i uzeti ključeve i dokumentaciju vozila pod osobnu kontrolu, odnosno kada nije u mogućnosti predočiti ključeve i dokumente vozila prilikom povrata vozila iz najma,</li>
                  <li>vozilo koristio protivno njegovoj namjeni,</li>
                  <li>vozilo koristio po nerazvrstanim cestama,</li>
                  <li>vozilom upravljao neovlašteni korisnik, odnosno bilo koju štetu koju je izazvao neovlašteni korisnik,</li>
                  <li>vozilom upravljao vozač bez vozačke dozvole ili vozač kojem je vozačka dozvola oduzeta ili ima zabranu upravljanja motornim vozilima,</li>
                  <li>kršio prekogranična ili teritorijalna ograničenja, tj. ako je Korisnik vozio vozilo izvan granica Republike Hrvatske, a da to prethodno nije najavio Najmodavcu prilikom rezervacije ili preuzimanja vozila te za to dobio izričitu dozvolu,</li>
                  <li>vozilo oštećeno zbog kršenja prometnih propisa, ograničenja ili zabrana, namjerno ili zbog grube nepažnje Korisnika odnosno osoba pod njegovom kontrolom i osoba za koje on odgovara,</li>
                  <li>vozilo preopterećeno više od specifikacija proizvođača iz prometne dozvole ili je u vozilu bilo više osoba od dopuštenog,</li>
                  <li>utovar i istovar u vozilo vršen u trenutku kada je vozilo na prometnici,</li>
                  <li>propustio zaustaviti vozilo ili ostati na mjestu događaja nakon nastanka nesreće te pribaviti policijski zapisnik o događaju,</li>
                  <li>došlo do pucanja ili oštećenja gume ili do oštećenja guma zbog upotrebe kočnica,</li>
                  <li>došlo do oštećenja vozila i/ili motora vozila zbog naleta neprimjerenom brzinom na veću količine vode na prometnici,</li>
                  <li>šteta nastala zbog tereta koji se prevozio u vozilu ili na vozilu,</li>
                  <li>šteta nastala pražnjenjem akumulatora zbog krivnje Korisnika,</li>
                  <li>šteta nastala u unutrašnjosti vozila (osim ako je ista posljedica nezgode),</li>
                  <li>vozilo vraćeno u iznimno neurednom stanju te je potrebno izvanredno pranje vozila,</li>
                  <li>šteta nastala na vozilu nakon završetka ugovorenog trajanja najma odnosno nakon isteka odobrenog produženja trajanja najma,</li>
                  <li>vozilo koristio radi počinjenja kaznenih djela i u druge protuzakonite svrhe.</li>
                </ol>
                <p>8.14. Plaćanjem/uključenjem CDW, TP, CDR, WUG, SCDW i PAI pokrića nije pokrivena odgovornost Korisnika i u drugim okolnostima predviđenim zakonskim propisima i pravilima osiguranja, koja uređuju gubitak prava iz osiguranja. Također, navedena pokrića ne pokrivaju štete prouzročene ratnim operacijama ili pobunama, niti štete zbog gubitka/oštećenja obvezne i dodatne opreme vozila, ključeva i dokumenata.</p>
                <p>8.15. Ukoliko Korisnik mimo Najmodavca ugovori s nekom drugom pravnom ili fizičkom osobom neko osiguranje/pokriće, takvo osiguranje/pokriće nije obvezujuće za Najmodavca.</p>
                <p>8.16. Odgovornost Korisnika za nestanak dijelova, dodatne opreme, ključeva i dokumenata vozila, osim ako je isto posljedica prometne nesreće, nije pokrivena CDW, TP, WUG, CDR i SCDW osiguranjem.</p>
                <p>8.17. Korisnik je odgovoran za svoje osobne stvari ostavljene u vozilu te nema pravo potraživati od Najmodavca naknadu štete za eventualni nestanak tih stvari iz vozila.</p>
                <p>8.18. Naknada za obradu štete (DHF) – naknada koja se Korisniku naplaćuje za obradu štete na vozilu koja je nasta- la tijekom trajanja i u svezi s Ugovorom o najmu, sukladno važećem Cjeniku Najmodavca i uvjetima koji su važeći za predmetni Ugovor o najmu. Naknada se obračunava neovisno o naplati same štete ili učešća u šteti.</p>
                <p>
                  8.19.
                  {' '}
                  <strong>BASIC PROTECT</strong>
                  {' '}
                  je paket koji uključuje osiguranja: CDW i TP.
                </p>
                <p>
                  {' '}
                  <strong>SMART PROTECT</strong>
                  {' '}
                  je paket koji uključuje osiguranja: CDW, TP, CDR i WUG.
                </p>
                <p>
                  {' '}
                  <strong>PREMIUM PROTECT</strong>
                  {' '}
                  je paket koji uključuje osiguranja: CDW, TP, SCDW, WUG, PAI i DHF.
                </p>
                <h3 id="9-dodatne-naknade-i-usluge">9. Dodatne naknade i usluge</h3>
                <p>
                  9.1
                  {' '}
                  <strong>RA (Pomoć na cesti)</strong>
                  – dodatak za tehničku pomoć/asistenciju na cesti
                </p>
                <p>RA – Pomoć na cesti podrazumijeva organizaciju pomoći na cesti od strane Najmodavca u slučaju nepokretnosti vozila tijekom trajanja Ugovora o najmu vozila.</p>
                <p>9.1.1. Ukoliko Korisnik pri potpisu Ugovora o najmu vozila plati RA, obveza Najmodavca je:</p>
                <ol>
                  <li>osigurati svu potrebnu pomoć nakon što ga je Korisnik kontaktirao putem broja tehničke službe Najmodavca</li>
                  <li>ako je potrebno, organizirati vučnu službu za nepokretno vozilo do najbližeg servisa/poslovnice Najmodavca, u kojem slučaju trošak vučne službe podmiruje Najmodavac.</li>
                </ol>
                <p>U slučaju krivnje ili grube nepažnje Korisnika za nepokretnost vozila (natočeno pogrešno gorivo, probijena/uništena guma, oštećeno kvačilo vozila, izgubljeni ključevi vozila, zaglavljeno vozilo te druge štete na vozilu koje su izazvale nepokretnost) taj trošak će se u cijelosti naplatiti Korisniku sukladno Cjeniku Najmodavca.</p>
                <p>9.1.2. Ukoliko Korisnik pri potpisu Ugovora o najmu vozila odluči ne ugovoriti RA, a nastupi nepokretnost vozila, usluge koje Najmodavac u tom slučaju pruži Korisniku biti će naplaćene sukladno Cjeniku Najmodavca, uključujući i trošak vučne službe.</p>
                <p>
                  9.2.
                  {' '}
                  <strong>Dozvola za prelazak granice</strong>
                  {' '}
                  – dozvola za prelazak granice Republike Hrvatske
                </p>
                <p>Korisniku nije dozvoljen izlazak unajmljenim vozilom izvan granica Republike Hrvatske bez prethodne dozvole Najmodavca. Korisnik je o tome dužan prilikom unajmljivanja obavijestiti Najmodavca. Ukoliko Najmodavac odobri izlazak vozilom izvan Republike Hrvatske, isto se navodi u Ugovor o najmu te se za navedeno naplaćuje naknada po Cjeniku Najmodavca. U tom slučaju Korisniku vrijede sva osiguranja koja je definirao na početku najma.</p>
                <p>Ukoliko Korisnik ne obavijesti Najmodavca o izlasku vozilom izvan Republike Hrvatske, to čini na vlastitu odgovornost te mu sva osiguranja, koja je pri sklapanju Ugovora o najmu ugovorio više ne vrijede.</p>
                <p>Najmodavac ne dozvoljava izlazak iznajmljenim vozilom u sljedeće zemlje: Srbija, Kosovo, Albanija, Sjeverna Makedonija, Grčka, Rumunjska, Bugarska, Moldavija, Ukrajina, Bjelorusija, Estonija, Latvija, Litva i Rusija. Iznimno, ukoliko Najmodavac navedeno dopusti, dozvola Najmodavca mora biti izričito pisanim putem navedena na Ugovoru o najmu. U protivnom, Korisnik navedeno čini na vlastitu odgovornost te mu sva osiguranja, koja je pri sklapanju Ugovora o najmu ugovorio više ne vrijede.</p>
                <p>
                  9.3.
                  {' '}
                  <strong>FIT (Ferry/Island/Transit permission)</strong>
                  {' '}
                  – dodatak za prijevoz vozila na trajektima, osiguranje na otocima i tranzit kroz Neum (BiH)
                </p>
                <p>Ukoliko Korisnik vozilom prelazi tranzit kroz mjesto Neum (Bosna i Hercegovina) na putu iz/za krajnji jug Republike Hrvatske, obvezan je to najaviti Najmodavcu. Korisnik je također obvezan obavijestiti Najmodavca o korištenju trajekta i odlasku vozilom na otoke. Najmodavac za to može Korisniku naplatiti naknadu sukladno Cjeniku.</p>
                <p>Ukoliko Korisnik ne ugovori FIT dodatak, a koristi vozilo u tranzitu kroz područje Neuma (BiH), na trajektu i na otocima, ne vrijede mu ugovorena osiguranja navedena u Ugovoru o najmu.</p>
                <p>
                  9.4.
                  {' '}
                  <strong>Naknada za vraćanje vozila u drugu poslovnicu</strong>
                  {' '}
                  – dodatak za povrat vozila u mjestu različitom od mjesta preuzimanja vozila
                </p>
                <p>Ukoliko Korisnik izvrši povrat vozila u mjestu/gradu različitom od mjesta/grada u kojem je sklopio Ugovor o najmu i preuzeo vozilo, Najmodavac će mu za navedeno naplatiti dodatnu naknadu sukladno Cjeniku.</p>
                <p>
                  9.5.
                  {' '}
                  <strong>Stanje goriva</strong>
                </p>
                <p>Korisnik je dužan unajmljeno vozilo po isteku Ugovora o najmu vratiti s minimalno onolikom količinom goriva koja je u vozilu evidentirana prilikom preuzimanja vozila. U protivnom, Najmodavac će nedostajuću količinu goriva Korisniku naplatiti sukladno Cjeniku.</p>
                <p>
                  9.6.
                  <em>Lokalna dostava/preuzimanje vozila </em>
                  {' '}
                  – usluga koja podrazumijeva preuzimanje/povrat vozila na adresi koju odredi Korisnik
                </p>
                <p>Ukoliko Najmodavac na to pristane, Korisnik može odrediti neku drugu adresu na koju će mu iznajmljeno vozilo dostaviti ili, po završetku Ugovora o najmu, s koje će ga preuzeti djelatnik Najmodavca. Najmodavac će Korisniku za navedeno naplatiti dodatnu naknadu sukladno Cjeniku.</p>
                <p>
                  9.7.
                  {' '}
                  <strong>Naknada za preuzimanje/vraćanje vozila izvan radnog vremena</strong>
                  {' '}
                  – usluga koja podrazumijeva preuzimanje/povrat vozila izvan radnog vremena Najmodavca
                </p>
                <p>Ukoliko Korisnik preuzima ili vraća unajmljeno vozilo izvan radnog vremena Najmodavca, Najmodavac će Korisniku za navedeno naplatiti dodatnu naknadu sukladno Cjeniku.</p>
                <p>
                  9.8.
                  {' '}
                  <strong>Zamjensko vozilo</strong>
                  {' '}
                  – usluga koja podrazumijeva pružanje zamjenskog vozila u slučaju kvara, prometne nesreće i drugih situacija koje su uzrokovale nepokretnost ili nemogućnost korištenja vozila
                </p>
                <p>U slučaju kvara, prometne nesreće i drugih situacija koje su uzrokovale nepokretnost ili nemogućnost korištenja vozila Najmodavac će Korisniku u razumnom roku osigurati zamjensko vozilo.</p>
                <p>Na zamjensko vozilo analogno se primjenjuju sva prava i obveze Korisnika koje proizlaze iz prvotno sklopljenog Ugovora o najmu (Ugovora o najmu tijekom kojeg je nastupila nepokretnost ugovorenog vozila) i ovih Općih uvjeta.</p>
                <h3 id="10-dokumenti-i-dodatna-oprema">10. Dokumenti i dodatna oprema</h3>
                <p>10.1. Sva vozila Najmodavca iznajmljuju se s potrebnim dokumentima i opremom te je Korisnik cijelo vrijeme trajanja najma vozila odgovoran za tu opremu i dokumente.</p>
                <p>10.2. U slučaju da Korisnik prilikom sklapanja Ugovora o najmu preuzme dodatnu opremu (npr. GPS, WI-FI, dječje sjedalo i sl.), odgovoran je i za tu dodatnu opremu.</p>
                <p>10.3. U slučaju da Korisnik izgubi dokumente, opremu ili dodatnu opremu, snosit će trošak naknade štete koja je zbog toga nastupila Najmodavcu sukladno Cjeniku Najmodavca.</p>
                <h3 id="11-raskid-ugovora-o-najmu">11. Raskid ugovora o najmu</h3>
                <p>11.1. Najmodavac ima pravo u svakom trenutku raskinuti Ugovor o najmu i odmah preuzeti vozilo u posjed, a sva potraživanja prema Najmoprimcu sukladno Ugovoru o najmu i ovim Općim uvjetima odrediti dospjelima, ako Korisnik ne postupa sukladno odredbama ovih Općih uvjeta i Ugovora o najmu ili ako je vozilo oštećeno. Prestanak Ugovora o najmu prema ovoj odredbi ne dovodi u pitanje druga prava Najmodavca regulirana ovim Općim uvjetima i Ugovorom o najmu.</p>
                <h3 id="12-prometni-prekr-aji-parking-kazne">12. Prometni prekršaji/parking kazne</h3>
                <p>12.1. Korisnik se smatra odgovornim za sve prometne prekršaje i parking kazne počinjene za vrijeme trajanja Ugovora o najmu. U slučaju da Korisnik te kazne ne plati, Korisnik je suglasan da mu ih zajedno s administrativnim troškovima naplati Najmodavac.</p>
                <p>12.2. Najmodavac može, u slučaju da dobije obavijest o prometnom prekršaju ili parking kazni počinjenoj za vrijeme trajanja Ugovora o najmu, o tome obavijestiti Korisnika te dostaviti potrebne informacije tijelima nadležnim za izdavanje takve obavijesti.</p>
                <p>12.3. Najmodavac će naplatiti Korisniku administrativnu naknadu sukladno važećem Cjeniku za pokriće troškova obrade i slanja obavijesti nadležnom tijelu o Korisniku vezanom uz prometne prekršaje i parking kazne.</p>
                <p>12.4. U slučaju da Najmodavac bude u obvezi platiti naknade za prometne prekršaje ili parking kazne, Najmodavac će nakon plaćanja istih teretiti račun Korisnika za iznos plaćene naknade uvećane za kamate i druge troškove.</p>
                <p>12.5. Najmodavac zadržava pravo naplatiti gore navedene troškove, a da prethodno ne obavijesti Korisnika. Državni porezi, takse i sl. će se naplatiti sukladno važećoj zakonskoj regulativi.</p>
                <h3 id="13-za-tita-podataka">13. Zaštita podataka</h3>
                <p>13.1. Odredbe Ugovora o najmu predstavljaju povjerljive podatke u smislu važećih propisa.</p>
                <p>13.2. Korisnik dozvoljava da se u svezi s Ugovorom o najmu vrši prikupljanje, upotreba i obrada njegovih osobnih podataka za interne potrebe Najmodavca, ali i radi dostavljanja tih podataka nadležnim državnim i drugim javnim tijelima te pravnim osobama, sve u smislu Opće uredbe o zaštiti osobnih podataka.</p>
                <p>13.3. Najmodavac će podatke koristiti u svrhu koja je navedena u Općoj uredbi o zaštiti osobnih podataka te primjenjuje maksimalne tehničke, organizacijske i kadrovske mjere u cilju zaštite osobnih podataka Korisnika.</p>
                <p>13.4. Korisnik je upoznat da ima pravo povući Izjavu o privoli u svakom trenutku na način da se obrati službeniku za zaštitu osobnih podataka Najmodavca putem kontakta koji je javno objavljen na web stranicama Najmodavca.</p>
                <h3 id="14-zavr-ne-odredbe">14. Završne odredbe</h3>
                <p>14.1. Ukoliko se ovi Opći uvjeti uz hrvatski jezik sačine i kao prijevod na bilo kojem drugom stranom jeziku, hrvatski jezik je mjerodavan za rješavanje svih nesukladnosti u njihovom tumačenju.</p>
                <p>14.2. U slučaju spora, postupat će stvarno nadležni sud u Zagrebu (Republika Hrvatska).</p>
                <p>14.3. Ovi Opći uvjeti najma vozila stupaju na snagu dana 1.3.2022. te se njima stavljaju izvan snage prethodno važeći Opći uvjeta najma.</p>
                <p>
                  14.4. Ovi Opći uvjeti najma istaknuti su u svim poslovnicama Najmodavca kao i na službenoj internet stranici Najmodavca:
                  {' '}
                  <a href="https://livcar.hr">livcar.hr</a>
                </p>
              </div>
            </div>
          </div>
          <div className="card padded" style={{ marginTop: '2rem' }}>
            <div className="content terms">
              <div className="col-24 col-md-16">
                <h3 style={{ marginTop: 0 }}>Obavijest o načinu podnošenja pisanih prigovora potrošača (korisnika usluge)</h3>
                <p>Sukladno čl. 10. st. 3. Zakona o zaštiti potrošača (Narodne novine br. 41/14) te odredbi čl.6. st.1. toč.3. Zakona o pružanju usluga u turizmu (Narodne novine br. 130/17) obavještavamo potrošače (korisnike usluga) da nezadovoljstvo u odnosu na kupljeni proizvod ili pruženu uslugu mogu dostaviti u pisanoj formi u poslovnim prostorijama, putem pošte ili elektroničke pošte na adresi:</p>
                <p>
                  <strong>Livcar rent-a-car</strong>
                  <br />
                  Rapska 12, 10000 Zagreb
                  <br />
                  OIB: 94762205298
                  <br />
                  E-mail adresa:
                  {' '}
                  <a href="mailto:info@livcar.hr">info@livcar.hr</a>
                </p>
                <p>Na podneseni prigovor bez odgađanja će se pisanim putem potvrditi primitak prigovora, te će trgovac u pisanom obliku odgovoriti u roku od 15 dana od dana zaprimanja prigovora.</p>
                <p>U PRIGOVORU JE POTREBNO NAVESTI SVOJE IME, PREZIME I ADRESU NA KOJU ĆEMO POSLATI ODGOVOR NA PRIGOVOR U ZAKONSKOM ROKU OD 15 DANA.</p>
              </div>
            </div>
          </div>
        </Component.Grid>
      </Component.Container>
    </>
  ) : (
    <div className="terms">
      <Component.Scroll />
      <Component.Container grid>
        <Component.Grid>
          <h1 className="title" style={{ margin: '2rem 0' }}>
            Rental Terms &amp; Conditions
          </h1>
          <div className="card padded">
            <div className="content">
              <div className="col-24 col-md-16">
                <h2 className="mt-5 mb-3">GENERAL RENTAL TERMS AND CONDITIONS</h2>
                <p>
                  These General Terms of Lease, including annexes, form an integral part of the Rental Agreement, and
                  are applied to it in its entirely, unless the derogation of some provision of the General Terms of
                  Lease is provided by the special provisions of the Rental Agreement, in which case such special
                  provisions from the Rental Agreement shall prevail.
                </p>
                <h3 className="mt-5 mb-3">1. GENERAL TERMS</h3>
                <p>
                  1.1
                  {' '}
                  <strong>Lessor </strong>
                  - company LIVCORP doo, as the owner of the brand Livcar rent-a-car, based in Zagreb (Republic of Croatia), at the address Ugljanska 6 (branch office in Rapska 12), Croatia, VAT:
                  94762205298.
                </p>
                <p>
                  1.2
                  {' '}
                  <strong>Lessee </strong>
                  - a natural or legal person who or on whose behalf the vehicle is rented,
                  signs the Rental Agreement along with the Vehicle Condition Report on the basis of which he leases the
                  vehicle and is responsible for compliance with all provisions of the General Terms of Lease as well as
                  the conditions of the Rental Agreement.
                </p>
                <p>
                  1.3
                  {' '}
                  <strong>Driver </strong>
                  - a natural person listed in the Rental Agreement as &quot;Driver&quot;, signs the
                  Rental Agreement and the Vehicle Condition Report on the basis of which he rents the vehicle and is
                  responsible for compliance with all provisions of the General Terms of Lease and the conditions of the
                  Rental Agreement. The Driver who takes over the vehicle on behalf of the Lessee as a legal person and
                  signs the Rental Agreement guarantees that he has the authority and is jointly and severally liable
                  with that legal person for compliance with all provisions of the General Terms of Lease as well as the
                  fulfillment of obligations under the Rental Agreement. One or more drivers may be listed on the Rental
                  Agreement (Driver 1, Driver 2, Driver 3, Driver 4…).
                </p>
                <p>
                  1.4
                  {' '}
                  <strong>User</strong>
                  - the Lessee and the Drivers from the item 1.3 of these General Terms of
                  Lease are hereinafter referred to as &quot;User&quot;. All Users are jointly and severally liable for the
                  performance and compliance with all provisions of the General Terms of Lease and the conditions of the
                  Rental Agreement.
                </p>
                <p>
                  1.5
                  {' '}
                  <strong>Rental Agreement </strong>
                  – a contract signed prior to taking over the vehicle, defining
                  the make, model and registration number of the vehicle, date of the collection and the return of the
                  vehicle, rental price, payment method, additional equipment and services, insurance included in the
                  price, treatment in case of damage and immobility of the vehicle and other rights and obligations
                  which both contracting parties fully accept by concluding the Rental Agreement.
                </p>
                <p>
                  1.6
                  {' '}
                  <strong>Vehicle Condition Report </strong>
                  (&quot;Check out / in report&quot;) – an internal act of the
                  Lessor that contains all relevant information about the vehicle before and after the rental period,
                  especially the state of mileage and equipment, fuel amount, damage and any defects on the rented
                  vehicle. The Vehicle Condition Report is considered an integral part of each Rental Agreement and the
                  Driver is obliged to sign it before and after the end of the duration of the Rental Agreement.
                </p>
                <p>
                  1.7
                  {' '}
                  <strong>Price List for Damage Charges</strong>
                  {' '}
                  – an internal act of the Lessor containing the
                  damage charges and fees for all vehicle make and models, which the Lessor in accordance with the
                  Rental Agreement charges the User in case of scratches, dents and vehicle parts that need to be
                  repaired / replaced, hereinafter &quot;Price List&quot;.
                </p>
                <p>
                  1.8
                  {' '}
                  <strong> Rental, Additional Insurance, Fees and Equipment Price List </strong>
                  {' '}
                  – an internal act
                  of the Lessor which defines the rental prices of all vehicle models, additional insurance that the
                  User may additionally contract when signing the Rental Agreement and equipment that is additionally
                  rented as well as other fees charged by the Lessor to the User based on the General Terms of Lease and
                  the Rental Agreement, hereinafter referred to as the &quot;Price List&quot;.
                </p>
                <p>
                  1.9
                  {' '}
                  <strong>Lessee&apos;s Statement of Damage to the Vehicle </strong>
                  – internal form of the Lessor which
                  the Driver is obliged to fill in and sign if during the lease there was a traffic accident or some
                  other event, which resulted in any visible or invisible damage to the vehicle.
                </p>
                <p>
                  1.10
                  {' '}
                  <strong>Statement of Consent </strong>
                  – a statement signed by the User giving consent to the
                  Lessor to collect and process his personal data in accordance with the General Data Protection
                  Regulation for the purpose of fulfilling the obligations from the Rental Agreement.
                </p>
                <p>
                  1.11
                  {' '}
                  <strong>Unauthorized user </strong>
                  any person who is not listed in the Rental Agreement as the
                  Lessee / Driver, as well as a person who does not meet the prescribed conditions for driving the
                  rented class and category of vehicle or whose driver&apos;s license has been revoked.
                </p>
                <p>
                  1.12
                  {' '}
                  <strong> Vehicle </strong>
                  – a vehicle precisely described in the Rental Agreement (make, model,
                  registration number).
                </p>
                <p>1.13 The driver can be:</p>
                <p>
                  - a person aged 21 or over with a valid driver&apos;s license for at least 2 years. Exceptionally, for
                  vehicle categories (FVMR, FFMR, XTAR, PDAR, PVAR, PFAR, LDAR, LVAR, XDAR, XFAR, FKMR) the minimum
                  requirements are: 25 years of age with 5 years of driving experience
                </p>
                <p>
                  - persons who have a valid credit card accepted by the Lessor and by which the User guarantees for
                  obligations that may arise from the lease and in connection with the lease of the vehicle in
                  accordance with the General Terms of Lease and the Rental Agreement.
                </p>
                <p>
                  When renting a vehicle, the User is obliged to present to the Lessor the originals of valid documents
                  (identity card / passport and driver&apos;s license) as a precondition for renting a vehicle.
                </p>
                <h3 className="mt-5 mb-3">2. VEHICLE COLLECTION AND RETURN</h3>
                <p>
                  2.1 After concluding the Rental Agreement, the Lessor undertakes to deliver the vehicle to the User,
                  who meets the rental conditions from item 1.13, in a technically correct and fully functioning
                  condition and with all the necessary documentation, accessories and mandatory equipment.
                </p>
                <p>
                  2.2 By signing the Rental Agreement and the Vehicle Condition Report, the User confirms that he has
                  taken over the vehicle in good condition, with the associated equipment and the necessary
                  documentation, and that he accepts the price and all other rental conditions.
                </p>
                <p>
                  2.3 When taking over the vehicle, the User is obliged to check the condition of the vehicle and
                  equipment in the usual way and report defects to the Lessor, if any, and the Lessor is obliged to
                  record them in the Vehicle Condition Report.
                </p>
                <p>
                  2.4 When returning the vehicle from the lease, the Lessor is obliged to inspect the vehicle and
                  equipment with the User and record visible damages and defects on the vehicle, if any.
                </p>
                <p>
                  2.5 The User is obliged to return the vehicle to the agreed place and within the period specified in
                  the Rental Agreement, in the condition in which he collected it, with the relevant documents and all
                  equipment he borrowed at the beginning of the lease and the amount of fuel that must be the at least
                  at the same level as at the time of the vehicle&apos;s collection.
                </p>
                <p>
                  2.6 The loss of documents, keys, license plates or equipment is not covered by any standard or
                  additional insurance and the User is responsible for the resulting damage, which will be charged in
                  accordance with the Lessor&apos;s Price List.
                </p>
                <p>
                  2.7 Collection and return of the vehicle is possible during working hours in the Lessor&apos;s branches,
                  and collection and return outside working hours is charged according to the Lessor&apos;s Price List.
                  Delivery and collection of vehicles outside the Lessor&apos;s branches is possible with the prior consent
                  of the Lessor and with additional payment depending on the distance from the branch.
                </p>
                <p>
                  2.8 If the User returns the vehicle outside the working hours of the Lessor&apos;s branch, he is
                  responsible for the vehicle until the moment when it is inspected by the Lessor&apos;s employees. In that
                  case, the User is also responsible for any damage to the vehicle, parking / traffic violations and
                  similar penalties, until the moment when the vehicle is inspected by the Lessor&apos;s employees.
                </p>
                <h3 className="mt-5 mb-3">3. VEHICLE USE AND USER OBLIGATIONS</h3>
                <p>3.1 The User undertakes:</p>
                <p>
                  a) to return the vehicle to the place and within the period determined by the Rental Agreement in the
                  same condition, with all the equipment and with at least the same amount of fuel
                </p>
                <p>
                  b) personally request an extension of the agreed rental period from the Lessor at least 24 hours
                  before the expiration of the rental period, otherwise it will be considered that the User has
                  illegally appropriated the vehicle
                </p>
                <p>
                  c) not use the vehicle for driver training, transport or towing of other vehicles or trailers, paid
                  passenger transport, races, endurance tests, speed tests and for illegal purposes (e.g., for
                  committing criminal offenses and other illegal acts and offenses)
                </p>
                <p>
                  d) to use the vehicle, for his own needs and in accordance with the purpose of the vehicle and that
                  the vehicle will not be given to unauthorized users and third parties
                </p>
                <p>
                  e) to use the vehicle correctly and apply all reasonable care, i.e., the attention of a conscientious
                  businessman when using, driving and parking the vehicle
                </p>
                <p>f) not to smoke in the vehicle</p>
                <p>
                  g) always lock the vehicle after leaving, close the windows and take the keys and documentation of
                  the vehicle and always have them under personal control
                </p>
                <p>h) to drive only on public roads, respecting all traffic regulations</p>
                <p>
                  i) to take care of the regular technical correctness of the vehicle, i.e., regularly check and
                  maintain the prescribed level of all fluids in the vehicle, especially coolant, oil, AdBlue additive,
                  tire pressure and use only the type of fuel specified for the rented vehicle
                </p>
                <p>j) not to make any modifications to the vehicle</p>
                <p>k) to bear all costs regarding fuel, tolls, parking, misdemeanor and other similar fees</p>
                <p>
                  l) not to transport or allow the transport of more passengers or goods in the vehicle than the
                  maximum allowed according to the traffic permit of the rented vehicle
                </p>
                <p>
                  m) not to leave with the vehicle outside the borders of the Republic of Croatia, unless previously
                  agreed with the Lessor when booking or taking over the vehicle, for which the Lessor will charge a fee
                  in accordance with the Price List
                </p>
                <p>n) not to assume any obligations on behalf of the Lessor regarding the vehicle and its use</p>
                <p>o) not to sublet the rented vehicle or lend the vehicle to other persons</p>
                <p>
                  p) not to use the vehicle under the influence of alcohol, sedatives, sleeping pills, barbiturates,
                  narcotics, hallucinogens and other drugs
                </p>
                <p>
                  q) to comply with the speed limits and other traffic rules laid down by the law of the country in
                  which the vehicle is driven
                </p>
                <p>r) not to change the data on the speedometer or odometer</p>
                <p>
                  s) to stop driving and immediately inform the Lessor if the vehicle signals a warning or an error on
                  the instrument panel or if the User considers that the vehicle requires mechanical inspection or
                  repair
                </p>
                <p>
                  t) to ensure that all drivers authorized to use the vehicle during the rental period are fully
                  acquainted with all the provisions of the General Terms of Lease and with the conditions of the Rental
                  Agreement
                </p>
                <p>
                  u) to make the vehicle available to the Lessor in the territory of the Republic of Croatia, if the
                  vehicle needs or urgently needs some service action, change of tires, etc.
                </p>
                <p>
                  v) to ensure that the vehicle is located in the territory of the Republic of Croatia and make it
                  available to the Lessor for the purpose of performing a technical / preventive inspection and renewal
                  of registration.
                </p>
                <p>
                  3.2 If the User violates any of the provisions of the item 3.1 of the General Terms of Lease, he
                  undertakes to compensate the Lessor for any damage that would occur to him as a result, and the amount
                  of which will be determined by the Lessor.
                </p>
                <p>
                  3.3 According to the Rental Agreement, the User does not acquire the right of ownership over the
                  vehicle, but the exclusive right of use in accordance with the provisions of the Rental Agreement and
                  the General Terms of Lease.
                </p>
                <p>
                  3.4 The User is responsible for the rented vehicle for the entire duration of the Rental Agreement,
                  regardless of whether the vehicle is in motion or parked.
                </p>
                <p>
                  3.5 In the event that the Lessor compensates any damage caused in connection with the possession, use
                  or condition of the vehicle to third parties, or pays any amount for the same reason, the User
                  acknowledges the Lessor&apos;s right of recourse and undertakes to reimburse any amount paid, including
                  interest and costs.
                </p>
                <p>
                  3.6 If the Lessee is a legal person, he may exceptionally, with the prior written consent of the
                  Lessor, give the rented vehicle for use to its employees, who meet all the conditions of item 1.13 of
                  these General Terms of Lease and in that case is obliged to acquaint them with these General Terms of
                  Lease along with the Rental Agreement. By signing the Rental Agreement, the User expressly confirms
                  that he is fully responsible for the actions of these persons in relation to the vehicle and is
                  obliged to compensate the Lessor for all damages and pay all costs and other fees caused by these
                  persons in connection with the use of vehicles. This in no way relieves the User of responsibility for
                  compliance with all provisions of the General Terms of Lease and the Rental Agreement.
                </p>
                <h3 className="mt-5 mb-3">4. RENTAL EXTENSION</h3>
                <p>
                  4.1 In the event that the User wishes to extend the agreed rental period, he is obliged to notify the
                  Lessor at least 24 hours before the expiration of the Rental Agreement and obtain his written consent.
                  In that case, the User must come to the nearest branch of the Lessor, sign and take over the Rental
                  Agreement with the newly agreed date of return of the vehicle and leave an additional guarantee if
                  necessary.
                </p>
                <p>
                  4.2 In case of non-compliance with the item 4.1, it will be considered that the User has
                  misappropriated the vehicle and the Lessor reserves the right to use all legal measures to confiscate
                  the vehicle in question from the User.
                </p>
                <p>
                  4.3 If the User returns the vehicle after the date specified in the Rental Agreement, the Lessor will
                  perform a new calculation of the rental price of the vehicle in accordance with the Price List.
                </p>
                <h3 className="mt-5 mb-3">5. RENTAL PAYMENT</h3>
                <p>
                  5.1 When concluding the Rental Agreement, the User must have a valid credit card as a payment
                  guarantee.
                </p>
                <p>
                  5.2 The User agrees with the debit of the pre-authorization amount on the credit card. The amount
                  depends on the category of the rented vehicle. The release of the pre-authorized amount from the card
                  depends on the bank that issued the card.
                </p>
                <p>
                  5.3 The credit card holder must be present when collecting the rented vehicle and his details must be
                  stated in the Rental Agreement as the Lessee or Driver. It is not possible to use a credit card as a
                  payment guarantee without the presence of the credit card holder.
                </p>
                <p>
                  5.4 The User is obliged to pay the basic rental price and all additional services he contracted
                  (e.g., child seat, GPS, after hour fee, etc.), as well as all additional fees, services and costs
                  specified in the Rental Agreement.
                </p>
                <p>
                  5.5 The means of payment can be: credit card, electronic / debit card, banknotes or transaction
                  payment (only with the prior consent of the Lessor).
                </p>
                <p>
                  5.6 If the User settles his costs for renting a vehicle with a credit card, by signing the Rental
                  Agreement he authorizes the Lessor to charge the rental costs directly to the credit card holder
                  without a signed slip form.
                </p>
                <p>
                  5.7 By signing the Rental Agreement, the User authorizes the Lessor to charge the credit card holder
                  the cost of damage to the vehicle up to the amount of damage excess or the full amount of damage if he
                  did not comply with the General Terms of Lease in accordance with the agreed insurance package.
                </p>
                <p>
                  5.8 The User agrees to have his credit charged or to issue some other method of payment to the Lessor
                  for all repairs, breakdown or any other costs discovered after the vehicle is returned of which the
                  User did not inform the Lessor when returning the vehicle.
                </p>
                <p>
                  5.9 If the User settles his obligations for renting a vehicle on the basis of the submitted offer of
                  the Lessor, he is obliged to pay the indicated amount within the deadline and under the conditions
                  specified in the offer.
                </p>
                <p>
                  5.10 The User undertakes to settle the invoice at the latest at the time of returning the vehicle
                  according to the details specified in the Rental Agreement. The amount is paid in accordance with the
                  active Price List at the time of signing the Rental Agreement. In case of late payment, legal default
                  interest and reminder costs are charged. In the event that the vehicle is returned to a location other
                  than the one where the vehicle was collected, the final calculation of the rental price is made in the
                  Lessor&apos;s branch where the vehicle was returned.
                </p>
                <p>
                  5.11 By signing the Rental Agreement, the User accepts that the following are responsible for the
                  payment of all items of the Rental Agreement:
                  <br />
                  {' '}
                  - Lessee
                  <br />
                  {' '}
                  - Drivers: in case the Lessee refuses
                  to pay in full or in part the obligation under the Vehicle Rental Agreement.
                </p>
                <p>
                  5.12 All items of the Rental Agreement will be calculated in EURO (EUR). In case of currency
                  conversion, the middle exchange rate of the Croatian National Bank (HNB) will be used, unless
                  otherwise agreed.
                </p>
                <h3 className="mt-5 mb-3">
                  6. SPECIAL OBLIGATIONS OF USERS IN CASE OF DAMAGE, MALEFUNCTION, TRAFFIC
                  ACCIDENT, THEFT AND LOSS OF DOCUMENTATION
                </h3>
                <p>
                  6.1 The User undertakes to protect the interests of the Lessor and his insurance company in the event
                  of an accident by:
                </p>
                <p>
                  a) collecting personal data of the participants in the accident (name, surname, VAT number/OIB,
                  address, identity card number, driver&apos;s license number, user of the vehicle, vehicle&apos;s insurance
                  company information, vehicle insurance policy number, contact phone, etc.)
                </p>
                <p>b) securing or removing the vehicle before leaving it</p>
                <p>
                  c) informing the nearest police station and the Lessor about the damage (even in case of minor
                  damage) and by obtaining a police record
                </p>
                <p>
                  d) immediately calling and waiting for the arrival of police officers to conduct an official
                  inspection and inform the Lessor, in case of damage or if there are injured persons in the accident,
                  as well as in all cases of obvious guilt of other persons.
                </p>
                <p>
                  e) enclosing all police reports on the accident or damage, as well as the results of the breathalyzer
                  test, when returning the vehicle
                </p>
                <p>
                  f) filling out the Lessee&apos;s Statement of Damage to the Vehicle and enclose copies of the driver&apos;s
                  license (on both sides), upon returning the vehicle
                </p>
                <p>
                  g) immediately calling the police in order to fill out the police report and perform an alcohol test
                  for potentially large (hidden) damage to the vehicle caused by collision, impact, etc. and the
                  consequent immobility of the vehicle.
                </p>
                <p>
                  6.2 In the event of an accident, if the User fails to act in accordance with the provisions of the
                  item 6.1, the User accepts liability for all consequences and damages that would occur to the Lessor
                  and will be charged for the full amount of damages.
                </p>
                <p>
                  6.3 If the vehicle involved in the accident is damaged, malfunctioning or requires repair or rescue
                  and if it is no longer fully functioning, regardless of the cause, the User must about it immediately
                  notify the Lessor. Furthermore, the User must fill out the Lessee&apos;s Statement of Damage to the Vehicle
                  and ensure that no further deterioration or even greater damage is caused to the vehicle.
                </p>
                <p>
                  6.4 The User is not allowed to organise or undertake any repairs without the written consent of the
                  Lessor, except to the extent necessary to prevent further damage to the vehicle or other property.
                </p>
                <p>
                  6.5 In the event of a traffic accident or vehicle theft / disappearance, the User is obligated to
                  call the police immediately and request a police report of the event and by notifying the Lessor
                  without delay.
                </p>
                <p>
                  6.6 The cost and charge of the re-creation of the lost supporting documentation for the vehicle is
                  charged to the User in accordance with the Lessor&apos;s Price List.
                </p>
                <h3 className="mt-5 mb-3">7. VEHICLE MAINTENANCE</h3>
                <p>
                  7.1 The User undertakes to maintain the vehicle in good condition, regularly check the engine, the
                  level of fluids in the vehicle, especially oils, coolants, AdBlue additives and tire pressure. If the
                  User fails to do so, he is responsible to the Lessor for any damage that occurs as a result.
                </p>
                <p>
                  7.2 In the event that the rented vehicle needs to perform the regular technical inspection during the
                  rental period (according to the vehicle&apos;s mileage or the vehicle signals it in any other way), the
                  User must notify the Lessor and make the vehicle available to him on the territory of the Republic of
                  Croatia in order to be able to perform the regular technical inspection. In this case, the Lessor
                  shall make a replacement vehicle available to the User, if so agreed.
                </p>
                <p>
                  7.3 By signing the Rental Agreement, the User confirms that he has collected the vehicle in a
                  technically correct and fully functioning condition with all the necessary equipment and is obliged to
                  report to the Lessor as soon as possible any circumstance that affects the vehicle&apos;s functioning such
                  as worn tires, damaged body part (e.g., mirror), burnt out bulb, or anything else that significantly
                  affects driving safety. If the User fails to do so, he is responsible to the Lessor for all damage
                  that occurs as a result.
                </p>
                <p>
                  7.4 The User is obliged to immediately notify the Lessor of any warnings the vehicle signals, such as
                  an imminent technical inspection service warning, high engine temperature, scraping sound of brake
                  pads, traces of oil dripping under the vehicle, etc. If the User fails to do so, he is responsible to
                  the Lessor for any damage that occurs as a result.
                </p>
                <p>
                  7.5 The Lessor will reimburse the User for the necessary costs for oil, lubricants, regular technical
                  inspection costs, and minor repairs incurred during the lease, if the costs were incurred with prior
                  notice and with a written approval of the Lessor, except for washing the rented vehicle. The
                  reimbursement is done on the basis of an invoice after the payment had been carried out. The invoiced
                  party is the Lessor (with complete and accurate information) and will as such be accepted by the
                  Lessor. If it is determined that the User has unreasonably or unnecessarily replaced a part or a
                  device on the vehicle, the Lessor will not pay the User the value of that part, assembly or device. To
                  reimburse the above costs, the User must obtain the consent of the authorized person of the Lessor,
                  otherwise the reimbursement will not be approved.
                </p>
                <h3 className="mt-5 mb-3">8. INSURANCE / COVERAGE / DAMAGES</h3>
                <p>8.1 All vehicles are insured against liability for damage caused to a third party.</p>
                <p>
                  8.2
                  {' '}
                  <strong> Liability in damages / Damage excess (franchise)</strong>
                </p>
                <p>
                  The User&apos;s liability (except for liability for the loss of additional equipment and vehicle parts)
                  may be limited to the amount of damage excess (franchise), by paying a predefined fee according to the
                  Lessor&apos;s Price List, which must be stated in the Rental Agreement, but only under the condition that
                  the User did not cause damage through the violation of the provisions of these General Terms of Lease,
                  the Rental Agreement and legal regulations, or through intentional or improper use of the vehicle.
                </p>
                <p>
                  8.3 When returning the vehicle, the Lessor&apos;s employee will inspect the vehicle, determine any damage,
                  compare them with the Vehicle Condition Report from the moment of the vehicle&apos;s collection and charge
                  the User the amount of damage in accordance with the valid Price List of the Lessor.
                </p>
                <p>
                  8.4 If the vehicle has such damage that it is not possible to immediately assess the amount of
                  damage, an official estimate of the amount of required vehicle repairs will be requested and it will
                  be the basis for any action towards the User.
                </p>
                <p>
                  8.5
                  {' '}
                  <strong> CDW (Collision Damage Waiver) </strong>
                  – risk fee with damage excess
                  <br />
                  {' '}
                  By paying this
                  fee, the User&apos;s liability for damage to the vehicle is limited to the amount of the damage excess if
                  the User complies with all the provisions of these General Terms of Lease.
                  <br />
                  {' '}
                  If the User has CDW
                  coverage, he is liable for damages up to the amount of the damage excess, in accordance with the
                  Lessor&apos;s Price List for the agreed class / group of vehicles. The CDW does not cover the risks such
                  as: destruction / damage of car tires, rim or rim cover, destruction / damage of the vehicle&apos;s
                  undercarriage, interior of the vehicle, all vehicle windows and all damages for which the User did
                  not present a police report to the Lessor.
                </p>
                <p>
                  8.6
                  {' '}
                  <strong> TP (Theft Protection) </strong>
                  – fee for the risk of a vehicle theft with the damage
                  excess
                  <br />
                  {' '}
                  By paying this fee, the User&apos;s liability for damage due to vehicle theft is limited to the
                  amount of the franchise / damage excess in accordance with the Lessor&apos;s Price List, if the User
                  complies with all provisions of these General Terms of Lease.
                </p>
                <p>
                  8.7
                  {' '}
                  <strong> CDR (Collision Damage Reduction) </strong>
                  – damage excess can be partially reduced by
                  paying a supplement for partial reduction of the franchise according to the valid Price List, in which
                  case the User pays a reduced amount of franchise according to the Price List, if the User complies
                  with all provisions of these General Terms.
                  <br />
                  {' '}
                  Payment of CDR coverage does not cover the risks:
                  destruction / damage of tires, rim or rim cover, destruction / damage of vehicle undercarriage,
                  vehicle interior (unless the interior is damaged in an accident), all vehicle windows and all damage
                  incurred without a presented police report to the Lessor. CDR coverage does not exclude the
                  obligation to contact the police and the Lessor in case of vehicle damage and to obtain a police
                  record.
                </p>
                <p>
                  8.8
                  {' '}
                  <strong> SCDW (Super Collision Damage Waiver) </strong>
                  – damage excess (franchise) from the items
                  8.5 and 8.7 can be reduced by paying a franchise reduction fee according to the valid Lessor Price
                  List (SCDW), in which case the User does not pay the amount of the franchise if he complies with all
                  the provisions of these General Terms of Lease.
                  <br />
                  {' '}
                  Payment of SCDW coverage does not cover the
                  risks: destruction / damage of tires, rim or rim cover, destruction / damage of the vehicle&apos;s
                  undercarriage, vehicle interior (unless the interior is damaged in an accident), all vehicle windows
                  and all damage incurred without presenting a police record to the Lessor. SCDW coverage does not
                  exclude the obligation to contact the police and the Lessor in the event of damage to the vehicle
                  and to obtain a police record.
                </p>
                <p>
                  8.9
                  {' '}
                  <strong> WUG (Wheels, Undercarriage &amp; Glass Insurance) </strong>
                  – by paying this fee, the
                  cover for destruction / damage of tires, rim or rim cover, destruction / damage of the vehicle
                  undercarriage and vehicle windows is contracted.
                  <br />
                  {' '}
                  WUG coverage does not exclude the obligation to
                  contact the police and the Lessor in the event of damage to the vehicle and to obtain a police
                  record.
                </p>
                <p>
                  8.10
                  {' '}
                  <strong> PAI (Personal Accident Insurance) </strong>
                  – by paying a daily fee for passenger
                  insurance, the driver and passengers in the rented vehicle are insured against accidents where the
                  outcome is death, disability or injury treatment costs under the conditions and up to the amounts
                  prescribed by the insurance company with which the Lessor has an insurance policy in case of
                  accidents.
                </p>
                <p>
                  8.10.1 SECTION I
                  <br />
                  {' '}
                  Insurance of drivers and other passengers from the consequences of a traffic
                  accident while driving and operating motor vehicles owned or leased by the insurance contractor
                  (company LIVCORP doo)
                  <br />
                  {' '}
                  - in case of death of the driver and passenger as a result of an accident:
                  6636.00 EUR
                  <br />
                  {' '}
                  - in case of permanent disability as a result of an accident: up to
                  13,272.00 EUR
                </p>
                <p>
                  8.10.2 SECTION II
                  <br />
                  {' '}
                  - insurance of drivers and passengers in the event of a traffic accident
                  caused by the driver and through driver&apos;s fault when using a road vehicle: extended Vehicle insurance
                  plus coverage with the insurance sum of up to 6,636.00 EUR per person and per insured case.
                </p>
                <p>
                  8.11 In order to exercise the right to limit liability or reduction of the damage excess, the User
                  must prove that the vehicle was used properly in compliance with these General Terms of Lease, the
                  Rental Agreement and all legal regulations, as well as third party liability for damage by presenting
                  reliable documentation (police record, European report, etc.) no later than when returning the vehicle
                  / end of the lease, or when calculating the price, fees and damage charges from the Lessor. Otherwise,
                  they will not be able to use the limitation of liability or reduction of damage excess.
                </p>
                <p>
                  8.12 Loss of right to limitation of liability and loss of right from insurance
                  <br />
                  {' '}
                  All damages,
                  which are caused on the vehicle intentionally and by gross negligence of the User, are not included in
                  any type of insurance / coverage and as such will be charged to the User in full.
                </p>
                <p>
                  8.13 Payment / inclusion of CDW, TP, CDR, WUG, SCDW, BASIC PROTECT, SMART PROTECT or PREMIUM PROTECT
                  insurance package does not reduce the User&apos;s liability under following conditions:
                </p>
                <p>a) the User drove under the influence of alcohol, drugs or narcotics</p>
                <p>
                  b) the User drove the vehicle when it is unsafe or unsuitable for driving, and such a condition
                  occurred during the rental period, which caused or contributed to the damage of the vehicle and the
                  User was aware or had to be aware of the insecurity or unsuitability of the vehicle
                </p>
                <p>
                  c) mechanical failure, damage to the engine or drive mechanism of the vehicle and / or electrical or
                  electronic failure as a result of improper use of the vehicle. This exemption also applies to damage
                  to the engine or transmission system caused directly by any mechanical failure or breakage
                </p>
                <p>
                  d) damage to the vehicle due to lack of engine oil, pouring of incorrect oil or fuel, lack of
                  transmission or differential oil, coolants, AdBlue additives, as well as damage to the clutch or
                  transmission
                </p>
                <p>
                  e) vehicle used for racing, driver training, endurance, speed and reliability tests, rally races or
                  competitions, or for testing and preparation for any of the above
                </p>
                <p>
                  f) failure to lock the vehicle, close the windows and take the keys and documentation of the vehicle
                  and always have them under personal control, i.e., the User is unable to present the keys and
                  documents of the vehicle when returning the rented vehicle
                </p>
                <p>g) use of the vehicle contrary to its purpose</p>
                <p>h) use of the vehicle on unclassified roads</p>
                <p>
                  i) the vehicle was driven by an unauthorized User, i.e., any damage caused by an unauthorized
                  User
                </p>
                <p>
                  j) the vehicle was driven by a driver without a driver&apos;s license or a driver whose driving license
                  has been revoked or who is prohibited from driving motor vehicles
                </p>
                <p>
                  k) violation of cross-border or territorial restrictions, i.e., if the User drove a vehicle outside
                  the borders of the Republic of Croatia, without first notifying the Lessor when booking or collecting
                  the vehicle and thus obtaining an explicit permit
                </p>
                <p>
                  l) vehicle damaged due to violation of traffic regulations, restrictions or prohibitions,
                  intentionally or due to gross negligence of the User or persons under his control and persons for whom
                  he is responsible
                </p>
                <p>
                  m) the vehicle is overloaded with weight exceeding the manufacturer&apos;s specifications stated in the
                  traffic license or there were more persons in the vehicle than allowed
                </p>
                <p>n) loading and unloading into the vehicle performed at the moment when the vehicle is on the road</p>
                <p>
                  o) failure to stop the vehicle or stay at the scene after the accident occurred and obtain a police
                  record about the event
                </p>
                <p>p) the tire is flat or damaged or the tires are damaged due to the use of brakes</p>
                <p>
                  q) damage to the vehicle and / or engine of the vehicle due to a collision at an inappropriate speed
                  with a large amount of water on the road
                </p>
                <p>r) damage caused by cargo transported in or on the vehicle</p>
                <p>s) damage caused by draining the battery due to the fault of the User</p>
                <p>
                  t) damage caused to the interior of the vehicle (unless if the latter is a consequence of an
                  accident)
                </p>
                <p>
                  u) the vehicle was returned in an extremely untidy condition and an extraordinary washing of the
                  vehicle is required
                </p>
                <p>
                  v) damage caused to the vehicle after the end of the agreed rental period or after the expiration of
                  the approved extension of the rental period
                </p>
                <p>
                  w) usage of the vehicle for the purpose of committing criminal offenses and for other illegal
                  purposes.
                </p>
                <p>
                  8.14 Payment / inclusion of CDW, TP, CDR, WUG, SCDW and PAI coverage does not cover the liability of
                  the User in other circumstances dictated by legal regulations and insurance rules, which regulate the
                  loss of insurance rights. Also, these coverages do not cover damages caused by war operations or
                  riots, nor damages due to loss / damage to obligatory or additional vehicle equipment, keys or
                  documentation.
                </p>
                <p>
                  8.15 If the User, in addition to the Lessor, contracts with another legal or natural person some
                  other insurance / coverage, such insurance / coverage is not binding for the Lessor.
                </p>
                <p>
                  8.16 The User&apos;s liability for the loss of parts, accessories, keys and documents of the vehicle,
                  unless the same is the result of an accident, is not covered by CDW, TP, WUG, CDR and SCDW
                  insurance.
                </p>
                <p>
                  8.17 The User is responsible for his personal belongings left in the vehicle and has no right to
                  claim from the Lessor compensation for possible disappearance or loss of these items from the
                  vehicle.
                </p>
                <p>
                  8.18 Damage Handling Fee (DHF) – a fee charged to the User for handling the damage to the vehicle
                  incurred during the duration and in connection with the rental Agreement, in accordance with the
                  applicable Lessor price List and the conditions applicable to the subject Rental Agreement. The fee is
                  charged regardless of the payment of the damage itself or participation in the damages (damage
                  excess).
                </p>
                <p>
                  8.19
                  {' '}
                  <strong>BASIC PROTECT</strong>
                  {' '}
                  is a package that includes insurance: CDW and TP.
                  <br />
                  {' '}
                  <strong>
                    SMART
                    PROTECT
                  </strong>
                  {' '}
                  is a package that includes insurance: CDW, TP, CDR and WUG.
                  <br />
                  {' '}
                  <strong>
                    PREMIUM
                    PROTECT
                  </strong>
                  {' '}
                  is a package that includes insurance: CDW, TP, SCDW, WUG, PAI and DHF.
                </p>
                <h3 className="mt-5 mb-3">9. ADDITIONAL FEES AND SERVICES</h3>
                <p>
                  9.1
                  {' '}
                  <strong>RA – Road Assistance</strong>
                  {' '}
                  – technical road assistance fee
                  <br />
                  {' '}
                  RA – Roads assistance
                  means the organization of roadside assistance by the Lessor in case of immobility of the vehicle
                  during the duration of the Vehicle Rental Agreement.
                </p>
                <p>
                  9.1.1 If the User pays RA when signing the Vehicle Rental Agreement, the Lessor&apos;s has the obligation
                  to:
                </p>
                <p>
                  a) provide all necessary assistance after the User has contacted him through the Lessor&apos;s technical
                  service number
                </p>
                <p>
                  b) if necessary, organise a towing service for a stationary vehicle to the nearest service / branch
                  of the Lessor, in which case the cost of the towing service is covered by the Lessor.
                </p>
                <p>
                  In case of fault or gross negligence to the vehicle by the User (wrong fuel, punctured / destroyed
                  tire, damaged vehicle clutch, lost vehicle keys, stuck vehicle and other damage to the vehicle that
                  caused its immobility) this cost will be charged in full to the User according to the Lessor’s Price
                  List.
                </p>
                <p>
                  9.1.2 If the User decides not to contract RA when signing the Vehicle Rental Agreement, and the
                  vehicle becomes immobile, the services provided by the Lessor in that case will be charged in
                  accordance with the Lessor&apos;s Price List, including the cost of the towing service.
                </p>
                <p>
                  {' '}
                  <strong>9.2 Cross boarding permission </strong>
                  {' '}
                  – permission for crossing the border of the Republic
                  of Croatia
                  {' '}
                  <br />
                  {' '}
                  The User is not allowed to drive with the rented vehicle outside the borders of the
                  Republic of Croatia without the prior permission of the Lessor. The User is obligated to inform the
                  Lessor about this when renting the vehicle. If the Lessor gives his approval for the border crossing
                  of the vehicle outside the Republic of Croatia, the same is stated in the Rental Agreement and a fee
                  is charged according to the Lessor&apos;s Price List. In that case, all insurances defined at the
                  beginning of the lease are valid for the User.
                  {' '}
                  <br />
                  {' '}
                  If the User does not inform the Lessor about
                  driving outside the Republic of Croatia with the rented vehicle, he does so at his own risk and
                  all insurances, which he agreed upon concluding the Rental Agreement, are no longer
                  valid.
                  {' '}
                  <br />
                  {' '}
                  The Lessor does not allow rented vehicles to go to the following countries: Kosovo,
                  Albania, North Macedonia, Greece, Romania, Bulgaria, Moldova, Ukraine, Belarus, Estonia, Latvia,
                  Lithuania and Russia. Exceptionally, if the Lessor allows the above, the Lessor&apos;s permission
                  must be explicitly stated in writing on the Rental Agreement. Otherwise, the User does the above
                  at his own risk and all insurances, which he agreed upon when concluding the Rental Agreement,
                  are no longer valid.
                </p>
                <p>
                  9.3
                  {' '}
                  <strong>FIT (Ferry/Island/Transit permission)</strong>
                  {' '}
                  – fee for transport of vehicles aboard
                  ferries, insurance on islands and transit through Neum (BiH)
                  <br />
                  {' '}
                  If the User crosses the border
                  through the town of Neum (in Bosnia and Herzegovina) on the way from / to the extreme south of the
                  Republic of Croatia, he is obliged to notify the Lessor. The User is also obliged to inform the
                  Lessor about the use of ferries and departure by vehicle to the islands. The Lessor may charge the
                  User a fee in accordance with the Price List.
                  <br />
                  {' '}
                  If the User does not agree with the FIT fee, and
                  uses the vehicle in transit through the area of Neum (BiH), or boards ferries to visit the
                  islands, the contracted insurances stated in the Rental Agreement are not valid.
                </p>
                <p>
                  9.4
                  {' '}
                  <strong> One-way fee </strong>
                  – supplement for returning a vehicle in a place other than the place
                  where the vehicle was collected.
                  <br />
                  {' '}
                  If the User returns the vehicle in a place / city other than the
                  place / city in which he concluded the Rental Agreement and collected the vehicle, the Lessor will
                  charge him an additional fee in accordance with the Price List.
                </p>
                <p>
                  9.5
                  {' '}
                  <strong> Fuel condition</strong>
                  {' '}
                  <br />
                  {' '}
                  The User is obliged to return the rented vehicle after the
                  expiration of the Rental Agreement with at least the same amount of fuel that was present in the
                  vehicle when the vehicle was collected by the User. Otherwise, the Lessor will charge the User for the
                  missing amount of fuel in accordance with the Price List.
                </p>
                <p>
                  9.6
                  {' '}
                  <strong> Local delivery / collection of the vehicle </strong>
                  – service that includes collection /
                  return of vehicles at the address specified by the User. If the Lessor agrees to this, the User may
                  specify another address to which the rented vehicle will be delivered to him or where the vehicle will
                  be collected by the Lessor&apos;s employee upon completion of the Rental Agreement. The Lessor will charge
                  the User an additional fee in accordance with the Price List.
                </p>
                <p>
                  9.7
                  {' '}
                  <strong> After hour fee </strong>
                  – service that includes taking over / returning of vehicles
                  outside working hours
                  <br />
                  {' '}
                  If the User collects or returns the rented vehicle outside of the working
                  hours of the Lessor, the Lessor will charge the User an additional fee in accordance with the Price
                  List.
                </p>
                <p>
                  9.8
                  {' '}
                  <strong> Replacement vehicle </strong>
                  – a service that includes a replacement vehicle is ensured
                  by the Lessor in the event of a breakdown, traffic accident and other situations that have caused
                  immobility or
                  <br />
                  {' '}
                  In the event of a breakdown, traffic accident and other situations that have caused
                  immobility or inability to use the rented vehicle, the Lessor shall provide the User with a
                  replacement vehicle within a reasonable time.
                  <br />
                  {' '}
                  All rights and obligations of the User arising
                  from the originally contracted Rental Agreement (Rental Agreement during which the originally
                  rented vehicle become immobile) apply analogously to the replacement vehicle.
                </p>
                <h3 className="mt-5 mb-3">10. DOCUMENTS AND ADDITIONAL EQUIPMENT</h3>
                <p>
                  10.1 All vehicles of the Lessor are rented with the necessary documents and equipment and the User is
                  responsible for those equipment and documents throughout the rental period.
                </p>
                <p>
                  10.2 In the event that the User picks up any additional equipment (e.g., GPS, WI-FI, child seat,
                  etc.) when concluding the Rental Agreement, he is also responsible for that additional equipment.
                </p>
                <p>
                  10.3 In the event that the User loses documents, equipment or additional equipment, he will bear the
                  cost of compensation for damage caused to the Lessor in accordance with the Lessor&apos;s Price List.
                </p>
                <h3 className="mt-5 mb-3">11. TERMINATION OF THE RENTAL AGREEMENT</h3>
                <p>
                  11.1 The Lessor has the right to terminate the Rental Agreement at any time and immediately take
                  possession of the vehicle, and all claims against the User in accordance with the Rental Agreement and
                  these General Terms of Lease are due, if the User does not act in accordance with these General Terms
                  or if the vehicle is damaged. Termination of the Rental Agreement under this provision is without
                  prejudice to other rights of the Lessor regulated by these General Terms of Lease and the Rental
                  Agreement.
                </p>
                <h3 className="mt-5 mb-3">12. TRAFFIC OFFENSES / PARKING PENALTIES</h3>
                <p>
                  12.1 The User is held liable for all traffic violations and parking fines committed during the rental
                  period of the Rental Agreement. In the event that the User does not pay these penalties, the User
                  agrees that the Lessor will calculate the latter and charge them together with the administrative
                  costs.
                </p>
                <p>
                  12.2 The Lessor may, in the event that it receives a notice of a traffic violation or parking penalty
                  committed during the term of the Rental Agreement, notify the User and provide the necessary
                  information to the authorities responsible for issuing such notice.
                </p>
                <p>
                  12.3 The Lessor will charge the User an administrative fee in accordance with the valid Price List to
                  cover the costs of processing and sending notifications to the competent authority related to traffic
                  violations and parking fines committed by the User.
                </p>
                <p>
                  12.4 In the event that the Lessor is obliged to pay fees for traffic violations or parking fines, the
                  Lessor will, after paying them, debit the User&apos;s account for the amount of the fee paid plus interest
                  and other costs.
                </p>
                <p>
                  12.5 The Lessor reserves the right to charge the above costs without prior notice to the User. State
                  taxes, fees, etc. will be collected in accordance with applicable legislation.
                </p>
                <h3 className="mt-5 mb-3">13. DATA PROTECTION</h3>
                <p>
                  13.1 The provisions of the Rental Agreement are confidential information in terms of applicable
                  regulations.
                </p>
                <p>
                  13.2 The User allows the collection, use and processing of his personal data for the internal needs
                  of the Lessor, but also for the purpose of submitting this data to the competent state and other
                  public bodies and legal persons, all in accordance with the General Regulation on Personal Data
                  Protection.
                </p>
                <p>
                  13.3 The Lessor will use the data for the purpose specified in the General Regulation on Personal
                  Data Protection and apply the maximum technical, organizational and personnel measures in order to
                  protect the personal data of the User.
                </p>
                <p>
                  13.4 The User is informed that he has the right to withdraw the Statement of Consent at any time by
                  contacting the Personal Data Protection Officer of the Lessor through a contact that is publicly
                  published on the website of the Lessor.
                </p>
                <h3 className="mt-5 mb-3">14. FINAL PROVISIONS</h3>
                <p>
                  14.1 If these General Terms and Conditions are drafted in addition to the Croatian language and as a
                  translation into any other foreign language, the Croatian language is competent to resolve any
                  discrepancies in their interpretation.
                </p>
                <p>
                  14.2 In the event of a dispute, the court with actual jurisdiction in Zagreb (Republic of Croatia)
                  will act.
                </p>
                <p>
                  14.3 These General Terms of Lease shall enter into force on 1.3.2022. and they invalidate the
                  previously valid General Terms of Lease.
                </p>
                <p>
                  14.4 These General Terms of Lease are displayed in all branches of the Lessor as well as on the
                  official website of the Lessor:
                  <a href="https://livcar.hr">livcar.hr</a>
                </p>
              </div>
            </div>
          </div>
          <div className="card padded" style={{ marginTop: '2rem' }}>
            <div className="content terms">
              <div className="col-24 col-md-16">
                <h3 className="mb-3" style={{ marginTop: 0 }}>
                  NOTIFICATION OF THE CUSTOMERS WRITTEN COMPLAINT SUBMISSION PROCEDURE (SERVICE
                  USERS)
                </h3>
                <p>
                  According to article 10, paragraph 3 of the consumer protection law
                  (National newspapers number 41/14) and provisions article 6, paragraph 1,
                  point 3 of tourism service providing law (National newspapers number 130/17),
                  we hereby notify the consumers (service users) that their dissatisfaction with the bought product or provided service can be delivered in
                  written form directly, by mail or e-mail on address:
                </p>
                <p>
                  <strong>Livcar rent-a-car</strong>
                  <br />
                  Rapska 12, 10000 Zagreb
                  <br />
                  VAT: 94762205298
                  <br />
                  Email address:
                  {' '}
                  <a href="mailto:info@livcar.hr">info@livcar.hr</a>
                </p>
                <p>
                  The complaint will be confirmed without delay and the complaint will be answered within 15 days of receipt.
                </p>
                <p>
                  IN THE COMPLAINT IT IS NECESSARY TO STATE YOUR FIRST AND LAST NAME AND ADDRESS TO WHICH WE WILL
                  SEND THE COMPLAINT REPLY WITHIN 15 DAYS (ACCORDING THE LAW).
                </p>
              </div>
            </div>
          </div>
        </Component.Grid>
      </Component.Container>
    </div>
  );
}

Terms.propTypes = {
  language: PropTypes.oneOf(['hr', 'en']).isRequired,
};

export default Terms;
