import React, { useState, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { navigate } from '@reach/router';
import * as Helper from '../helpers';
import * as Component from '../components';

function Search() {
  const [loading, setLoading] = useState(true);
  const [vehicles, setVehicles] = useState([]);
  const [activeCategories, setActiveCategories] = useState(new Set([]));
  const [activeTransmissions, setActiveTransmissions] = useState(new Set([]));

  useEffect(() => {
    (async () => {
      try {
        const results = await Helper.Api.get('vehicles');
        setVehicles(Helper.Sort.sortVehicles(results.data, false, true, true));
        setLoading(false);
      } catch (error) {
        await navigate('/');
      }
    })();
  }, []);

  function handleVehicleCategoryClick(vehicleCategory) {
    setActiveCategories((previousActiveCategories) => {
      const newActiveCategories = new Set(previousActiveCategories);

      if (previousActiveCategories.has(vehicleCategory)) {
        newActiveCategories.delete(vehicleCategory);
      } else newActiveCategories.add(vehicleCategory);

      return newActiveCategories;
    });
  }

  function handleTransmissionClick(transmissionType) {
    setActiveTransmissions((previousActiveTransmissions) => {
      const newActiveTransmissions = new Set(previousActiveTransmissions);

      if (previousActiveTransmissions.has(transmissionType)) {
        newActiveTransmissions.delete(transmissionType);
      } else newActiveTransmissions.add(transmissionType);

      return newActiveTransmissions;
    });
  }

  const vehicleCategories = [...new Set(vehicles.map(({ category }) => category.name))];
  const transmissionTypes = [...new Set(vehicles.map(({ transmission }) => transmission))];

  if (loading) return <Component.Loader />;

  return (
    <>
      <Component.Scroll />
      <Component.Container grid style={{ background: 'white' }}>

        <Component.Grid>
          <h1 className="title" style={{ margin: '2rem 0 0 0' }}>
            <FormattedMessage id="title.vehicles" />
          </h1>
        </Component.Grid>

        {/* Vehicle Category Filter */}
        <Component.Grid>
          <div className="filter-transmission">
            <div className="filter-pills">
              {vehicleCategories.map((vehicleCategory) => (
                <button
                  type="button"
                  key={vehicleCategory}
                  className={`pill ${activeCategories.has(vehicleCategory) ? 'active' : ''}`}
                  onClick={() => handleVehicleCategoryClick(vehicleCategory)}
                >
                  {vehicleCategory}
                </button>
              ))}
            </div>
            <div className="transmission-pills">
              {transmissionTypes.map((transmissionType) => (
                <button
                  type="button"
                  key={transmissionType}
                  className={`pill ${activeTransmissions.has(transmissionType) ? 'active' : ''}`}
                  onClick={() => handleTransmissionClick(transmissionType)}
                >
                  <FormattedMessage id={`vehicle.transmission.${transmissionType}`} />
                </button>
              ))}
            </div>
          </div>
        </Component.Grid>

        {/* Vehicle List */}
        {vehicles
          .filter((vehicle) => (!activeCategories.size
            || activeCategories.has(vehicle.category.name))
            && (!activeTransmissions.size || activeTransmissions.has(vehicle.transmission)))
          .map((vehicle) => (
            <Component.Grid key={vehicle.id} medium={6} large={4}>
              <Component.VehicleCard data={vehicle} />
            </Component.Grid>
          ))}

      </Component.Container>
    </>
  );
}

export default Search;
