import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { navigate } from '@reach/router';
import Icon from '@mdi/react';
import * as Path from '@mdi/js';
import * as Helper from '../helpers';
import * as Component from '../components';

function Search({ pickupTime, returnTime, category }) {
  const [loading, setLoading] = useState(true);
  const [vehicles, setVehicles] = useState([]);
  // const [sortByName, setSortByName] = useState(true);
  // const [sortAsc, setSortAsc] = useState(true);
  const [categoryName, setCategoryName] = useState('all');
  const [alert, setAlert] = useState({ text: null, type: null });

  useEffect(() => {
    (async () => {
      try {
        const results = await Helper.Api.get('vehicles', {
          params: {
            category,
            pickupTime,
            returnTime,
          },
        });
        // Sort vehicles immediately after fetching
        const sortedVehicles = Helper.Sort.sortVehicles(results.data, false, true, true);
        setVehicles(sortedVehicles);
        if (results.data.length === 0) {
          setAlert({
            type: 'danger',
            text: 'error.noResults',
          });
        }
        if (Number(category) !== 0 && results.data.length) {
          setCategoryName(results.data[0].category.name);
        }
        setLoading(false);
      } catch (error) {
        setAlert({
          type: 'danger',
          text: 'error.noResults',
        });
      }
    })();
  }, [category, pickupTime, returnTime]);

  const onClick = (vehicle) => {
    (async () => {
      try {
        const response = await Helper.Api.post('reservations', {
          vehicle,
          pickupTime,
          returnTime,
        });
        await navigate(`/reservation/${response.data.uuid}`);
      } catch (error) {
        // eslint-disable-next-line
        console.log(error.response);
      }
    })();
  };

  if (loading) return <Component.Loader />;

  return (
    <>
      <Component.Scroll />
      <Component.Container grid>

        <Component.Grid>
          <h1 className="title" style={{ margin: '2rem 0 0 0' }}>
            <FormattedMessage id="title.searchResults" />
          </h1>
        </Component.Grid>

        <Component.Grid small={4} medium={2} large={2}>
          <Component.Button type="link" to="/" variant="secondary" block>
            <Icon path={Path.mdiArrowLeft} size={1} color="white" />
          </Component.Button>
        </Component.Grid>

        {/* <Component.Grid small={4} medium={2} large={1}>
          <Component.Button
            variant="secondary"
            block
            color="white"
            type="button"
            onClick={() => {
              setVehicles(
                (prevVehicles) => Helper.Sort.sortVehicles(prevVehicles, false, true, false, true),
              );
              setSortByName((prevSortByName) => !prevSortByName);
            }}
          >
            <Icon path={sortByName ? Path.mdiFormatTextVariant :
            Path.mdiCurrencyUsd} size={1} color="white" />
          </Component.Button>
        </Component.Grid>

        <Component.Grid small={4} medium={2} large={1}>
          <Component.Button
            variant="secondary"
            block
            type="button"
            onClick={() => {
              setVehicles((prevVehicles) => (
                Helper.Sort.sortVehicles(prevVehicles, false, true, false, true)
              ));
              setSortAsc((prevSortAsc) => !prevSortAsc);
            }}
          >
            <Icon path={sortAsc ? Path.mdiSortAscending : Path.mdiSortDescending}
            size={1} color="white" />
          </Component.Button>
        </Component.Grid> */}

        <Component.Grid medium={6} large={2}>
          <FormattedMessage id="rent.vehicleCategory">
            {(label) => (
              <Component.InputDummy
                name="category"
                value={categoryName}
                label={label}
              />
            )}
          </FormattedMessage>
        </Component.Grid>

        <Component.Grid medium={6} large={3}>
          <FormattedMessage id="rent.pickupTime">
            {(label) => (
              <Component.DateTimeDummy
                value={Number(pickupTime)}
                label={label}
              />
            )}
          </FormattedMessage>
        </Component.Grid>

        <Component.Grid medium={6} large={3}>
          <FormattedMessage id="rent.returnTime">
            {(label) => (
              <Component.DateTimeDummy
                value={Number(returnTime)}
                label={label}
              />
            )}
          </FormattedMessage>
        </Component.Grid>

        {alert.text && (
          <Component.Grid>
            <Component.Alert type={alert.type} text={alert.text} />
          </Component.Grid>
        )}

        {vehicles.map((vehicle) => (
          <Component.Grid key={vehicle.id} medium={6} large={4}>
            <Component.Vehicle
              data={vehicle}
              pickupTime={Number(pickupTime)}
              returnTime={Number(returnTime)}
              onClick={onClick}
            />
          </Component.Grid>
        ))}

      </Component.Container>
    </>
  );
}

Search.propTypes = {
  pickupTime: PropTypes.node,
  returnTime: PropTypes.node,
  category: PropTypes.node,
};

Search.defaultProps = {
  pickupTime: 0,
  returnTime: 0,
  category: 0,
};

export default Search;
