import React from 'react';
import { Link } from '@reach/router';
import '../styles/header-logo.css';
import logo from '../images/logo.png';

function HeaderLogo() {
  return (
    <Link className="header-logo" to="/">
      <img src={logo} alt="Hogwarts Online" />
    </Link>
  );
}

export default HeaderLogo;