import React from 'react';
import Icon from '@mdi/react';
import * as Path from '@mdi/js';
import Container from './Container';
import Grid from './Grid';

function Loader() {
  return (
    <Container grid>
      <Grid>
        <h1 style={{ textAlign: 'center', minHeight: 'calc(100vh - 16rem)', lineHeight: 'calc(100vh - 16rem)' }}>
          <Icon size={2} spin color="#000" path={Path.mdiLoading} />
        </h1>
      </Grid>
    </Container>
  );
}

export default Loader;
