import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import '../styles/data-row.css';

function DataRow({ label, content }) {
  return (
    <div className="data-row">
      <span className="label"><FormattedMessage id={label} /></span>
      <span className="content">{content}</span>
    </div>
  );
}

DataRow.propTypes = {
  label: PropTypes.string.isRequired,
  content: PropTypes.node.isRequired,
};

export default DataRow;
