import React, { useRef, useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Icon from '@mdi/react';
import * as Path from '@mdi/js';
import '../styles/vehicle.css';

function VehicleCard({ data }) {
  // const [imageHeight, setImageHeight] = useState(0);
  const vehicleRef = useRef(null);

  useLayoutEffect(() => {
    if (vehicleRef.current) {
      // setImageHeight((vehicleRef.current.offsetWidth * 3) / 4);
    }
  }, [vehicleRef]);

  const {
    manufacturer,
    model,
    image,
    category,
    transmission,
    fuel,
    seats,
    doors,
    // power,
  } = data;

  const title = `${manufacturer.name} ${model}`;

  return (
    <div className="vehicle" ref={vehicleRef}>
      <h3>
        <b>{title}</b>
        <FormattedMessage id="vehicle.similar" />
      </h3>
      <div className="image" style={{ backgroundImage: `url(https://office.livcar.hr/storage/${image})`, height: '150px' }} />
      <div className="pills">
        <span className="pill">
          <Icon size="1rem" color="white" path={Path.mdiCar} />
          <span>{category.name}</span>
        </span>
        <span className="pill">
          <Icon size="1rem" color="white" path={Path.mdiGasStation} />
          <span><FormattedMessage id={`vehicle.fuel.${fuel}`} /></span>
        </span>
        {/* <span className="pill">
          <Icon size="1rem" color="white" path={Path.mdiEngine} />
          <span>
            {power}
            {' '}
            <FormattedMessage id="vehicle.power.hp" />
          </span>
        </span> */}
        <span className="pill">
          <Icon size="1rem" color="white" path={Path.mdiCarShiftPattern} />
          <span><FormattedMessage id={`vehicle.transmission.${transmission}`} /></span>
        </span>
        <span className="pill">
          <Icon size="1rem" color="white" path={Path.mdiCarSeat} />
          <span>{seats}</span>
        </span>
        <span className="pill">
          <Icon size="1rem" color="white" path={Path.mdiCarDoor} />
          <span>{doors}</span>
        </span>
      </div>
    </div>
  );
}

VehicleCard.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.number.isRequired,
    manufacturer: PropTypes.shape({ name: PropTypes.string }),
    category: PropTypes.shape({ name: PropTypes.string }),
    model: PropTypes.string,
    image: PropTypes.string,
    transmission: PropTypes.oneOf(['M', 'A']),
    fuel: PropTypes.oneOf(['P', 'D']),
    seats: PropTypes.number,
    doors: PropTypes.number,
    power: PropTypes.number,
    price: PropTypes.number,
  }).isRequired,
};

export default VehicleCard;
