import React from 'react';
import PropTypes from 'prop-types';
import '../styles/grid.css';

function Grid({
  children,
  small,
  medium,
  large,
  reservation
}) {
  return (
    <div className={`small-${small} medium-${medium} large-${large} ${reservation ? "reservation" : ""}`}>
      {children}
    </div>
  );
}

Grid.propTypes = {
  children: PropTypes.node.isRequired,
  small: PropTypes.oneOf([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]),
  medium: PropTypes.oneOf([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]),
  large: PropTypes.oneOf([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]),
};

Grid.defaultProps = {
  small: 12,
  medium: 12,
  large: 12,
};

export default Grid;
