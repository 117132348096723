/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { useFormik } from 'formik';
import { navigate } from '@reach/router';
import Icon from '@mdi/react';
import * as Path from '@mdi/js';
import * as Helper from '../helpers';
import * as Component from '../components';
import '../styles/table.css';

function Reservation({ uuid, language }) {
  const [loading, setLoading] = useState(true);
  const [processing, setProcessing] = useState(false);
  const [reservation, setReservation] = useState({});

  useEffect(() => {
    (async () => {
      try {
        const response = await Helper.Api.get(`reservations/${uuid}`);
        setReservation(response.data);
        setLoading(false);
      } catch (error) {
        await navigate('/');
      }
    })();

    return (async () => {
      try {
        setLoading(true);
        await Helper.Api.delete(`reservations/${uuid}`);
      } catch (error) {
        await navigate('/expired');
      }
    });
  }, [uuid]);

  useEffect(() => {
    const timer = setTimeout(() => {
      navigate('/expired');
    }, 660000);
    return () => clearTimeout(timer);
  });

  const initialValues = {
    name: '',
    surname: '',
    dateOfBirth: '',
    phone: '',
    email: '',
    paymentMethod: 0,
  };

  const validate = (values) => {
    const errors = {};

    if (!values.name) {
      errors.name = 'error.required';
    } else if (values.name.length > 25) {
      errors.name = 'error.tooLong';
    }

    if (!values.surname) {
      errors.surname = 'error.required';
    } else if (values.surname.length > 25) {
      errors.surname = 'error.tooLong';
    }

    if (!values.dateOfBirth) {
      errors.dateOfBirth = 'error.required';
    } else if (!Helper.Time.checkDate(values.dateOfBirth)) {
      errors.dateOfBirth = 'error.date';
    } else if (!Helper.Time.checkMinAge(values.dateOfBirth)) {
      errors.dateOfBirth = 'error.minAge';
    } else if (!Helper.Time.checkMaxAge(values.dateOfBirth)) {
      errors.dateOfBirth = 'error.maxAge';
    }

    if (!values.phone) {
      errors.phone = 'error.required';
    } else if (values.phone.length < 8 || values.phone.length > 20) {
      errors.phone = 'error.phone';
    }

    if (!values.email) {
      errors.email = 'error.required';
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
      errors.email = 'error.email';
    }

    if (Number(values.paymentMethod) !== 0 && Number(values.paymentMethod) !== 1) {
      errors.paymentMethod = 'error.invalidSelection';
    }

    return errors;
  };

  const onSubmit = async (values) => {
    try {
      setProcessing(true);
      const response = await Helper.Api.post(`reservations/${reservation.uuid}`, { ...values, language }, { timeout: 0 });
      await navigate(`/success/${response.data.number}`);
    } catch (error) {
      await navigate('/expired');
    }
  };

  const formik = useFormik({ initialValues, validate, onSubmit });

  if (loading) return <Component.Loader />;

  if (processing) {
    return (
      <Component.Container grid>
        <Component.Grid>
          <h1 style={{ textAlign: 'center', minHeight: 'calc(100vh - 16rem)', lineHeight: 'calc(100vh - 16rem)' }}>
            <Icon size={2} spin color="#fff" path={Path.mdiLoading} />
            <span style={{ display: 'inline-block', marginLeft: '2rem' }}>{language === 'hr' ? 'Rezervacija u obradi...' : 'Your reservation is being processed...'}</span>
          </h1>
        </Component.Grid>
      </Component.Container>
    );
  }

  return (
    <>
      <Component.Scroll />
      <form onSubmit={formik.handleSubmit}>
        <Component.Container grid reservation={true}>

          <Component.Grid small={12} medium={6} large={6}>

            <h1 className="title" style={{ margin: '2rem 0' }}>
              <FormattedMessage id="rent.reservationDetails" />
            </h1>

            <Component.DataRow
              label="rent.reservationNumber"
              content={reservation.number}
            />
            <Component.DataRow
              label="rent.address"
              content="Rapska 12, Zagreb"
            />
            <Component.DataRow
              label="rent.pickupTime"
              content={`${Helper.Time.formatDate(reservation.start)} ${Helper.Time.formatTime(reservation.start)}`}
            />
            <Component.DataRow
              label="rent.returnTime"
              content={`${Helper.Time.formatDate(reservation.end)} ${Helper.Time.formatTime(reservation.end)}`}
            />
            <Component.DataRow
              label="rent.vehicle"
              content={`${reservation.vehicle.manufacturer.name} ${reservation.vehicle.model}`}
            />
          </Component.Grid>

          <Component.Grid small={12} medium={6} large={6}>

            <h1 className="title" style={{ margin: '2rem 0' }}>
              <FormattedMessage id="rent.clientDetails" />
            </h1>

            <FormattedMessage id="form.name">
              {(label) => (
                <Component.Input
                  label={label}
                  name="name"
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.name && formik.errors.name}
                />
              )}
            </FormattedMessage>
            <FormattedMessage id="form.surname">
              {(label) => (
                <Component.Input
                  label={label}
                  name="surname"
                  value={formik.values.surname}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.name && formik.errors.name}
                />
              )}
            </FormattedMessage>
            <FormattedMessage id="form.dob">
              {(label) => (
                <Component.Input
                  label={label}
                  name="dateOfBirth"
                  mask="99/99/9999"
                  placeholder="DD/MM/YYYY"
                  value={formik.values.dateOfBirth}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.dateOfBirth && formik.errors.dateOfBirth}
                />
              )}
            </FormattedMessage>
            <FormattedMessage id="form.email">
              {(label) => (
                <Component.Input
                  label={label}
                  name="email"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.email && formik.errors.email}
                />
              )}
            </FormattedMessage>
            <FormattedMessage id="form.phone">
              {(label) => (
                <Component.Input
                  label={label}
                  name="phone"
                  value={formik.values.phone}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.phone && formik.errors.phone}
                />
              )}
            </FormattedMessage>
          </Component.Grid>

          <Component.Grid small={12} medium={12} large={12}>
            <h1 className="title"><FormattedMessage id="rent.priceDetails" /></h1>
            <div className="table-container">
              <table className="pricing">
                <thead>
                  <tr>
                    <FormattedMessage id="rent.startDate">{(label) => <th className="left w15">{label}</th>}</FormattedMessage>
                    <FormattedMessage id="rent.endDate">{(label) => <th className="left w15">{label}</th>}</FormattedMessage>
                    <FormattedMessage id="rent.days">{(label) => <th className="right w10">{label}</th>}</FormattedMessage>
                    <FormattedMessage id="rent.price">{(label) => <th className="right w10">{label}</th>}</FormattedMessage>
                    <th className="right w15">Neto</th>
                    <FormattedMessage id="rent.vat">{(label) => <th className="right w10">{label}</th>}</FormattedMessage>
                    <th className="right">Total</th>
                  </tr>
                </thead>
                <tbody>
                  {reservation.prices.map((price) => (
                    <tr key={price.id}>
                      <td className="left">{Helper.Time.formatDate(price.start)}</td>
                      <td className="left">{Helper.Time.formatDate(price.end)}</td>
                      <td className="right">{price.days}</td>
                      <td className="right">{(price.price - price.vat).toFixed(2)}</td>
                      <td className="right">{(price.total - price.total_vat).toFixed(2)}</td>
                      <td className="right">{price.total_vat.toFixed(2)}</td>
                      <td className="right">{price.total.toFixed(2)}</td>
                    </tr>
                  ))}
                </tbody>
                <tfoot>
                  <tr className="primary">
                    <td className="left total" colSpan={2}>Total</td>
                    <td className="right">{reservation.days}</td>
                    <td className="right">{reservation.prices.reduce((prev, current) => (prev + current.price - current.vat), 0).toFixed(2)}</td>
                    <td className="right">{reservation.prices.reduce((prev, current) => (prev + current.total - current.total_vat), 0).toFixed(2)}</td>
                    <td className="right">{reservation.prices.reduce((prev, current) => (prev + current.total_vat), 0).toFixed(2)}</td>
                    <td className="right">
                      {reservation.prices.reduce((prev, current) => (
                        prev + current.total
                      ), 0).toFixed(2)}
                      {' '}
                      EUR
                      {' '}
                      <br />
                      {/* {Helper.Currency.eurToHrk((reservation.prices.reduce((prev, current) => (
                        prev + current.total
                      ), 0))).toFixed(2)}
                      {' '}
                      HRK */}
                    </td>
                  </tr>
                  <tr>
                    <td className="left total" colSpan={6}><FormattedMessage id="form.deposit" /></td>
                    <td className="right eur-hrk-total">
                      {reservation.deposit.toFixed(2)}
                      {' '}
                      EUR
                      {/* <br />
                      {Helper.Currency.eurToHrk(reservation.deposit).toFixed(2)}
                      {' '}
                      HRK */}
                    </td>
                  </tr>
                  <tr>
                    <td className="left total" colSpan={7}><b><FormattedMessage id="form.insurance" /></b></td>
                  </tr>
                  <tr>
                    <td className="left total" colSpan={7}><FormattedMessage id="form.volumeDiscount" /></td>
                  </tr>
                </tfoot>
              </table>
            </div>
          </Component.Grid>

          <Component.Grid medium={6} large={6}>
            <FormattedMessage id="form.paymentMethod">
              {(label) => (
                <Component.Select
                  label={label}
                  value={Number(formik.values.paymentMethod)}
                  all={false}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  name="paymentMethod"
                  formik
                  options={[{
                    id: 0,
                    name: language === 'hr' ? 'Prilikom preuzimanja (gotovina, kartica)' : 'Upon pickup (cash, credit card)',
                  }, {
                    id: 1,
                    name: language === 'hr' ? 'Bankovna transakcija (instrukcije putem emaila)' : 'Bank transfer (instructions via email)',
                  }]}
                />
              )}

            </FormattedMessage>
          </Component.Grid>

          <Component.Grid medium={6} large={6}>
            <div />
          </Component.Grid>

          <Component.Grid>
            {language === 'hr' ? <p>Sve cijene su izražene u EUR.</p> : <p>All prices are in EUR.</p>}
            {language === 'hr' ? (
              <p>
                Rezervacijom prihvaćate
                {' '}
                <a href="/terms">uvjete korištenja</a>
                {' '}
                i
                {' '}
                <a href="/privacy">pravila privatnosti</a>
                .
              </p>
            ) : (
              <p>
                By submitting this form you agree to the
                {' '}
                <a href="/terms">Terms of Service</a>
                {' '}
                and the
                {' '}
                <a href="/privacy">Privacy Policy</a>
                .
              </p>
            )}
          </Component.Grid>

          <Component.Grid medium={3} large={2}>
            <FormattedMessage id="form.book">{(label) => (<Component.Button block>{label}</Component.Button>)}</FormattedMessage>
          </Component.Grid>

        </Component.Container>
      </form>
    </>
  );
}

Reservation.propTypes = {
  uuid: PropTypes.string,
  language: PropTypes.oneOf(['en', 'hr']).isRequired,
};

Reservation.defaultProps = {
  uuid: '',
};

export default Reservation;
