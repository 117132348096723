const sortVehicles = (vehicles, sortByName, sortByCategory, sortAsc) => {
  const customCategoryOrder = [
    'City Car',
    'Compact',
    'SUV',
    'Luxury',
    'Van',
    'Cargo',
  ];

  if (sortByCategory) {
    return vehicles.sort((a, b) => {
      const prevIndex = customCategoryOrder.indexOf(a.category.name);
      const currentIndex = customCategoryOrder.indexOf(b.category.name);

      // If both categories are found in custom order, compare their indices
      if (prevIndex !== -1 && currentIndex !== -1) {
        if (prevIndex === currentIndex) return 0; // They are equal
        return sortAsc ? prevIndex - currentIndex : currentIndex - prevIndex;
      }
      return prevIndex === -1 ? 1 : -1; // Put missing categories at the end
    });
  }

  if (sortByName) {
    return vehicles.sort((a, b) => {
      const prev = `${a.manufacturer.name} ${a.model}`.toUpperCase();
      const current = `${b.manufacturer.name} ${b.model}`.toUpperCase();
      if (prev === current) return 0;
      if (prev < current) return sortAsc ? -1 : 1;
      return sortAsc ? 1 : -1;
    });
  }

  return vehicles.sort((a, b) => {
    const prev = a.prices.length
      ? a.prices.reduce((min, prevPrice) => Math.min(min, prevPrice.value), Infinity)
      : a.price;
    const current = b.prices.length
      ? b.prices.reduce((min, prevPrice) => Math.min(min, prevPrice.value), Infinity)
      : b.price;
    return sortAsc ? prev - current : current - prev;
  });
};

const Sort = {
  sortVehicles,
};

export default Sort;
