import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import '../styles/alert.css';
import Icon from '@mdi/react';

function Alert({ type, text, icon }) {
  return (
    <div className={`alert ${type}`}>
      {icon && <Icon path={icon} size={1} />}
      <FormattedMessage id={text} />
    </div>
  );
}

Alert.propTypes = {
  type: PropTypes.oneOf(['success', 'danger', 'primary']).isRequired,
  text: PropTypes.string.isRequired,
  icon: PropTypes.node,
};

Alert.defaultProps = {
  icon: null,
};

export default Alert;
