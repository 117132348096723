import React from 'react';
import PropTypes from 'prop-types';
import '../styles/header-language.css';

function HeaderLanguage({ language, setLanguage }) {
  return (
    <div className="header__language">
      <button
        type="button"
        className="header__language__button"
        disabled={language === 'hr'}
        onClick={() => setLanguage('hr')}
      >
        HR
      </button>
      /
      <button
        type="button"
        className="header__language__button"
        disabled={language === 'en'}
        onClick={() => setLanguage('en')}
      >
        EN
      </button>
    </div>
  );
}

HeaderLanguage.propTypes = {
  language: PropTypes.oneOf(['hr', 'en']).isRequired,
  setLanguage: PropTypes.func.isRequired,
};

export default HeaderLanguage;
