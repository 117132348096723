import React from 'react';
import PropTypes from 'prop-types';
import * as Helper from '../helpers';
import '../styles/date-time-dummy.css';

function InputDummy({
  label,
  value,
}) {
  return (
    <div className="date-time-dummy">
      <span className="label">{label}</span>
      <span className="date">{Helper.Time.formatDate(value)}</span>
      <span className="time">{Helper.Time.formatTime(value)}</span>
    </div>
  );
}

InputDummy.propTypes = {
  value: PropTypes.number.isRequired,
  label: PropTypes.string.isRequired,
};

export default InputDummy;
