import React, { useState } from 'react';
import PropTypes from 'prop-types';
import * as Path from '@mdi/js';
import Icon from '@mdi/react';
import HeaderLanguage from './HeaderLanguage';
import HeaderLogo from './HeaderLogo';
import HeaderNavigation from './HeaderNavigation';
import HeaderToggle from './HeaderToggle';
import '../styles/header.css';

function Header({
  language,
  setLanguage,
}) {
  const [open, setOpen] = useState(false);
  return (
    <header className="header">
      <div className="ribbon">
        <div className="container">
          <a target="_blank" rel="noopener noreferrer" href="https://www.google.com/maps/place/Livcar+Rent+a+car/@45.799275,15.999117,17z/data=!4m5!3m4!1s0x0:0xaee23418e1c308d9!8m2!3d45.799252!4d15.9991117?hl=en">
            <Icon path={Path.mdiMapMarker} size={1} />
            <span>Hotel Livris | Rapska 12 | Zagreb</span>
          </a>
          <a href="tel:+385 98 633 893">
            <Icon path={Path.mdiPhoneClassic} size={1} />
            <span>+385 98 633 893</span>
          </a>
          <a href="mailto:info@livcar.hr">
            <Icon path={Path.mdiEmail} size={1} />
            <span>info@livcar.hr</span>
          </a>
          <HeaderLanguage setLanguage={setLanguage} language={language} />
        </div>
      </div>
      <div className="container">
        <HeaderToggle icon={Path.mdiMenu} onClick={() => setOpen(true)} />
        <HeaderLogo />
        {/* eslint-disable-next-line max-len */}
        <div aria-label="Backdrop" role="button" className={`backdrop mobile ${open && 'open'}`} onClick={() => setOpen(false)} />
        <HeaderNavigation open={open} setOpen={setOpen} />
      </div>
    </header>
  );
}

Header.propTypes = {
  language: PropTypes.string.isRequired,
  setLanguage: PropTypes.func.isRequired,
};

export default Header;
